import { useEnv } from '../../common/hooks/useEnv'
import useAccounts from '../../common/hooks/useAccounts'
import { UserPermission } from '../../common/types/user-permission'
import { type NavigationTarget } from '../../common/types/navigation-target'

function useBasketSettings (): {
  hasNextGenPurchasing: boolean
  basketUrl: string
  target: NavigationTarget
} {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { activeAccount } = useAccounts()

  const hasNextGenPurchasing = !!activeAccount?.permissions.includes(
    UserPermission.HasNextGenPurchasing
  )
  const basketUrl = hasNextGenPurchasing
    ? '/purchasing/basket'
    : `${REACT_APP_MA3_BASE_URL ?? ''}/Order/MyBasket.aspx`
  const target: NavigationTarget = hasNextGenPurchasing ? '_self' : '_blank'

  return {
    hasNextGenPurchasing,
    basketUrl,
    target
  }
}

export default useBasketSettings
