import { type ReactNode } from 'react'
import { format } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import {
  OrderRequestBasePriorityEnum,
  type OrderRequest
} from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import styles from '../assets/scss/OrderRequest.module.scss'

interface OrderRequestSummaryProps {
  orderRequest: OrderRequest
}

function OrderRequestSummaryPanel ({
  orderRequest
}: OrderRequestSummaryProps): ReactNode {
  const { t } = useTranslation()
  const { allAccounts, isLoading: isLoadingAllAccounts } = useAllAccounts()
  const {
    count,
    total,
    currency,
    lineItems,
    isLoading: isLoadingLineItems
  } = useOrderRequestLineItems(orderRequest?.id)
  const { suppliers } = useOrderRequestSummary(lineItems)

  const requestedByAccount = allAccounts?.content?.find(
    account => account.id === orderRequest.requested_by?.id
  )

  if (isLoadingAllAccounts || isLoadingLineItems) {
    return null
  }

  return (
    <MaPanel className={styles['summary-panel']}>
      <MaPanel.Header className={styles['summary-panel-header']}>
        <h4>
          {t('order_request.summary.title')}{' '}
          {orderRequest.priority &&
            orderRequest.priority !== OrderRequestBasePriorityEnum.NONE && (
              <span
                className={
                  styles[`priority-${orderRequest.priority.toLowerCase()}`]
                }
              >
                {t(
                  `order_request.priority.${orderRequest.priority.toLowerCase()}`
                )}
              </span>
          )}
        </h4>
      </MaPanel.Header>

      <MaPanel.Body className={styles['summary-panel-body']}>
        <p className={styles['summary-message']}>
          {orderRequest?.confidential && (
            <span className={styles.confidential}>
              {t('order_request.type.confidential')}
            </span>
          )}
          <Trans
            i18nKey={'order_request.summary.message.requested_by'}
            values={{
              requestedBy: requestedByAccount?.name,
              createdOn: format(
                new Date(orderRequest.created_date),
                'do MMMM yyyy'
              )
            }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.items'}
            values={{ count }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.suppliers'}
            values={{ supplier: suppliers?.[0]?.name, count: suppliers.length }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.total_cost'}
            values={{ price: total, currency }}
          />
          {orderRequest.required_by_date && (
            <>
              {' '}
              <Trans
                i18nKey={'order_request.summary.message.required_by_date'}
                values={{
                  requestedBy: orderRequest.required_by_date,
                  requiredByDate: format(
                    new Date(orderRequest.required_by_date),
                    'do MMMM yyyy'
                  )
                }}
              />
            </>
          )}
        </p>

        {orderRequest.reason && (
          <div className={styles.reason}>
            <h6>{t('order_request.summary.reason.title')}</h6>

            <p>{orderRequest.reason}</p>
          </div>
        )}
      </MaPanel.Body>

      <MaPanel.Footer className={styles['summary-panel-footer']}>
        <Button variant="primary" className="rounded" disabled={count < 1}>
          {t('order_request.action.request_approval')}
        </Button>
      </MaPanel.Footer>
    </MaPanel>
  )
}

export default OrderRequestSummaryPanel
