import { type ProductResource } from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import MaTab from '../../common/models/MaTab'
import { UserModule } from '../../common/types/user-module'
import ProductDetailsTabHazards from '../components/ProductDetailsTabHazards'
import ProductDetailsTabNotes from '../components/ProductDetailsTabNotes'
import ProductDetailsTabPurchaseHistory from '../components/ProductDetailsTabPurchaseHistory'
import ProductDetailsTabSpecifications from '../components/ProductDetailsTabSpecifications'
import useProductNotes from './useProductNotes'
import usePurchaseHistory from './usePurchaseHistory'
import useProductHazards from './useProductHazards'
import ProductDetailsTabDocumentation from '../components/ProductDetailsTabDocumentation'

function useProductDetailsTabs (product: ProductResource): MaTab[] {
  const { activeAccount } = useAccounts()
  const { hasData: hasNotesData } = useProductNotes(product.id)
  const { hasData: hasPurchaseHistoryData } = usePurchaseHistory(product.id)
  const { hasData: hasProductHazardsData } = useProductHazards(product.id)

  const tabs = [
    new MaTab(
      'specifications',
      <ProductDetailsTabSpecifications productId={product.id} />
    ),
    new MaTab(
      'notes',
      <ProductDetailsTabNotes productId={product.id} />,
      hasNotesData
    ),
    new MaTab(
      'purchase_history',
      <ProductDetailsTabPurchaseHistory productId={product.id} />,
      hasPurchaseHistoryData
    ),
    new MaTab(
      'hazards',
      <ProductDetailsTabHazards product={product} />,
      hasProductHazardsData,
      activeAccount?.modules.includes(UserModule.HasHSEControlModule)
    ),
    new MaTab(
      'documentation',
      <ProductDetailsTabDocumentation product={product} />,
      !!product.documentation
    )
  ]

  return tabs.filter(tab => tab.visible)
}

export default useProductDetailsTabs
