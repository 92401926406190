import { useContext } from 'react'
import ReactGA from 'react-ga4'
import useSWR from 'swr'
import { type ProductResource } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import {
  QuickAccessContext,
  QuickAccessDispatchContext
} from '../../quick-access/components/QuickAccessContextProvider'
import { QuickAccessAction } from '../../quick-access/hooks/useQuickAccessState'

interface UseFavouritesHook {
  allFavouriteProducts: ProductResource[]
  favouriteProducts: ProductResource[]
  totalFavourites: number
  isLoading: boolean
  search: string
  changeSearch: (search: string) => void
  setFavourite: (productId: string, value: boolean) => Promise<void>
}

function useFavourites (): UseFavouritesHook {
  const quickAccessState = useContext(QuickAccessContext)
  const quickAccessDispatch = useContext(QuickAccessDispatchContext)
  const { productsApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const {
    data: products,
    isLoading,
    mutate: mutateFavouriteProducts
  } = useSWR(
    accountId ? ['favourite-products', accountId] : null,
    async () =>
      await fetcher(productsApi.getFavourites, {
        size: 0,
        direction: 'desc',
        accountId
      })
  )

  const favouriteProducts: ProductResource[] = products?.content || []

  const filteredFavourites = favouriteProducts.filter(
    (favouriteProduct: ProductResource): boolean => {
      const searchSource: string = `${favouriteProduct?.description ?? ''} ${
        favouriteProduct.supplier?.name ?? ''
      } ${favouriteProduct.part_number ?? ''}`
        .trim()
        .toLowerCase()

      return searchSource.includes(
        quickAccessState.favouritesSearch.toLowerCase()
      )
    }
  )

  const sortedFavourites = filteredFavourites.sort((a, b) => {
    const descriptionA = a.description?.toLowerCase() ?? ''
    const descriptionB = b.description?.toLowerCase() ?? ''

    if (descriptionA === descriptionB) {
      return 0
    }

    return descriptionA > descriptionB ? 1 : -1
  })

  const setFavourite = async (
    productId: string,
    value: boolean
  ): Promise<void> => {
    if (!accountId) {
      return
    }

    ReactGA.event('action_item_list', {
      item_list_id: 'search_results',
      item_id: productId,
      action: value ? 'set_favourite' : 'unset_favourite'
    })

    try {
      await productsApi.setFavourite({
        productId,
        accountId,
        body: value
      })
      await mutateFavouriteProducts()
    } catch (error) {
      console.warn("Couldn't (un)mark product as favourite")
    }
  }

  const changeSearch = (filter: string): void => {
    quickAccessDispatch({
      type: QuickAccessAction.CHANGE_FAVOURITES_SEARCH,
      value: filter
    })
  }

  return {
    allFavouriteProducts: favouriteProducts,
    favouriteProducts: sortedFavourites,
    totalFavourites: favouriteProducts.length,
    search: quickAccessState.favouritesSearch,
    changeSearch,
    setFavourite,
    isLoading
  }
}

export default useFavourites
