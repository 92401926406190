import { type ReactElement, forwardRef } from 'react'
import { Card, type CardProps } from 'react-bootstrap'
import classNames from 'classnames'
import styles from '../assets/scss/MaCard.module.scss'

const MaCard = forwardRef(function MaCard (
  { className, ...props }: CardProps,
  ref
): ReactElement {
  return (
    <Card
      {...props}
      className={classNames(styles['ma-card'], className)}
      ref={ref}
    />
  )
})

export type MaCardProps = CardProps

export default Object.assign(MaCard, {
  Img: Card.Img,
  Title: Card.Title,
  Subtitle: Card.Subtitle,
  Body: Card.Body,
  Link: Card.Link,
  Text: Card.Text,
  Header: Card.Header,
  Footer: Card.Footer,
  ImgOverlay: Card.ImgOverlay
})
