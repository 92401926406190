import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SortDirection } from '../../common/types/sort-direction'
import {
  OrderRequestSortBy,
  type OrderRequestSortOption
} from '../types/order-request-sort-option'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'

export interface OrderRequestsSortSelectProps {
  value: OrderRequestSortOption
  onChange: (value: OrderRequestSortOption) => void
}

function OrderRequestsSortSelect ({
  value,
  onChange
}: OrderRequestsSortSelectProps): ReactElement {
  const { t } = useTranslation()

  return (
    <MaSelect
      aria-label={t('common.label.sort_by')}
      label={t('common.label.sort_by')}
      value={JSON.stringify(value)}
      onValueChange={value => {
        onChange(JSON.parse(value) as OrderRequestSortOption)
      }}
    >
      <MaSelectItem
        value={JSON.stringify({
          field: OrderRequestSortBy.CREATED_DATE,
          direction: SortDirection.ASC
        })}
      >
        {t('order_requests.sort.or_created_date_asc')}
      </MaSelectItem>
      <MaSelectItem
        value={JSON.stringify({
          field: OrderRequestSortBy.CREATED_DATE,
          direction: SortDirection.DESC
        })}
      >
        {t('order_requests.sort.or_created_date_desc')}
      </MaSelectItem>
      <MaSelectItem
        value={JSON.stringify({
          field: OrderRequestSortBy.REFERENCE,
          direction: SortDirection.ASC
        })}
      >
        {t('order_requests.sort.or_number_asc')}
      </MaSelectItem>
      <MaSelectItem
        value={JSON.stringify({
          field: OrderRequestSortBy.REFERENCE,
          direction: SortDirection.DESC
        })}
      >
        {t('order_requests.sort.or_number_desc')}
      </MaSelectItem>
    </MaSelect>
  )
}

export default OrderRequestsSortSelect
