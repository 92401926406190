import { useRef, useState, type ReactElement } from 'react'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatISO } from 'date-fns'
import {
  type OrderRequest,
  OrderRequestBasePriorityEnum
} from '@amici/myamici-api-client'
import useOrderRequest from '../hooks/useOrderRequest'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import MaConfirm from '../../common/components/MaConfirm'
import MaDatePicker from '../../common/components/MaDatePicker'
import MaCheckbox from '../../common/components/MaCheckbox'
import styles from '../assets/scss/OrderRequest.module.scss'

interface OrderRequestEditSummaryModalProps {
  show: boolean
  orderRequestId?: string
  onClose: () => void
}

function OrderRequestEditSummaryModal ({
  show,
  orderRequestId,
  onClose
}: OrderRequestEditSummaryModalProps): ReactElement {
  const { t } = useTranslation()
  const { allAccounts, isLoading: isLoadingAllAccounts } = useAllAccounts()
  const {
    data: orderRequest,
    isLoading,
    update,
    isUpdating
  } = useOrderRequest(orderRequestId)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)
  const [modalFullyVisible, setModalFullyVisible] = useState(false)
  const modalRef = useRef<any>(null)

  const datepickerContainer = modalRef?.current?.dialog

  const { control, register, getValues, setValue, watch, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      requestedBy: orderRequest?.requested_by?.id,
      requiredByDate: orderRequest?.required_by_date,
      priority: orderRequest?.priority,
      confidential: orderRequest?.confidential,
      reason: orderRequest?.reason
    },
    values: {
      requestedBy: orderRequest?.requested_by?.id,
      requiredByDate: orderRequest?.required_by_date,
      priority: orderRequest?.priority,
      confidential: orderRequest?.confidential,
      reason: orderRequest?.reason
    }
  })

  const handleClose = (): void => {
    if (isUpdating) {
      return
    }

    setModalFullyVisible(false)
    reset()
    onClose()
  }

  const handleUpdate = async (): Promise<void> => {
    if (!orderRequest || isBusy) {
      return
    }

    const requestedByAccount = allAccounts?.content?.find(
      account => account.id === getValues('requestedBy')
    )

    if (!requestedByAccount) {
      return
    }

    const updatedOrderRequest: OrderRequest = {
      ...orderRequest,
      requested_by: requestedByAccount,
      required_by_date: getValues('requiredByDate'),
      priority: getValues('priority'),
      confidential: getValues('confidential'),
      reason: getValues('reason')?.trim()
    }

    await update(updatedOrderRequest)
    await refreshHistory()

    handleClose()
  }

  const isBusy = isLoading || isUpdating || isLoadingAllAccounts

  return (
    <MaConfirm
      ref={modalRef}
      size="lg"
      show={show}
      disabled={isBusy}
      title={t('order_request.edit_form.title')}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      onShow={() => {
        setModalFullyVisible(true)
      }}
      onClose={handleClose}
      onConfirm={handleUpdate}
    >
      <Form className={styles['order-request-modal-form']}>
        <fieldset disabled={isBusy}>
          <Row>
            <FormGroup as={Col} lg="4" controlId="requested-by">
              <Form.Label>
                {t('order_request.form.label.requested_by')}
              </Form.Label>
              <Controller
                name="requestedBy"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.requested_by')}
                    value={value}
                    onValueChange={value => {
                      onChange(value)
                    }}
                  >
                    {allAccounts?.content?.map(account => (
                      <MaSelectItem key={account.id} value={account.id}>
                        {account.name}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="required-by-date">
              <Form.Label>
                {t('order_request.form.label.date_required')}
              </Form.Label>
              {modalFullyVisible && (
                <MaDatePicker
                  container={datepickerContainer}
                  selected={
                    watch('requiredByDate')
                      ? new Date(getValues('requiredByDate') ?? '')
                      : undefined
                  }
                  onSelect={day => {
                    setValue(
                      'requiredByDate',
                      day ? formatISO(day, { representation: 'date' }) : ''
                    )
                  }}
                />
              )}
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="priority">
              <Form.Label>{t('order_request.form.label.priority')}</Form.Label>
              <Controller
                name="priority"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.priority')}
                    onValueChange={value => {
                      onChange(value)
                    }}
                    value={value}
                  >
                    {Object.keys(OrderRequestBasePriorityEnum).map(key => (
                      <MaSelectItem
                        key={key}
                        value={
                          OrderRequestBasePriorityEnum[
                            key as keyof typeof OrderRequestBasePriorityEnum
                          ]
                        }
                      >
                        {t(
                          `order_request.form.value.priority.${key.toLowerCase()}`
                        )}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>

          <Row>
            <Controller
              name="confidential"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Form.Label className={styles.confidential}>
                  <MaCheckbox onCheckedChange={onChange} checked={value} />
                  {t('order_request.form.label.confidential')}
                </Form.Label>
              )}
            />
          </Row>

          <Row>
            <FormGroup as={Col} lg="12" controlId="reason">
              <Form.Label>{t('order_request.form.label.reason')}</Form.Label>
              <Form.Control
                as="textarea"
                className={styles.reason}
                {...register('reason', { maxLength: 500 })}
                maxLength={500}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestEditSummaryModal
