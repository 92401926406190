import {
  type ChangeEventHandler,
  useRef,
  type ReactElement,
  useState,
  type ChangeEvent
} from 'react'
import {
  ProductStatusEnum,
  ProductStockAvailabilityEnum,
  type LineItem,
  type ProductStock as ProductStockType,
  type SpendCategory
} from '@amici/myamici-api-client'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CloseButton, Form, Spinner } from 'react-bootstrap'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import classNames from 'classnames'
import ReactGA from 'react-ga4'
import MaCard, { type MaCardProps } from '../../common/components/MaCard'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import FallbackImage from '../../common/components/FallbackImage'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'
import ProductStockMessage from '../../common/components/ProductStockMessage'
import styles from '../assets/scss/BasketLineItemCard.module.scss'

export interface BasketLineItemCardProps extends MaCardProps {
  lineItem?: LineItem
  stockInfo?: ProductStockType
  price: number
  selected: boolean
  isBusy: boolean
  onQuantityChange: (lineItem: LineItem, quantity: number) => void
  onItemSelectedChange: (lineItem: LineItem, selected: CheckedState) => void
  onRemove: (lineItem: LineItem) => void
  onUpdate: (lineItem: LineItem) => void
}

const FULL_VIEW_MIN_WIDTH_PX = 524

function BasketLineItemCard ({
  lineItem,
  stockInfo,
  price,
  selected,
  isBusy,
  onQuantityChange,
  onItemSelectedChange,
  onRemove,
  onUpdate,
  className,
  ...props
}: BasketLineItemCardProps): ReactElement | null {
  const ref = useRef(null)
  const { t } = useTranslation()
  const fullView = useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  const [basketSpendCategories, setBasketSpendCategories] = useState(
    lineItem?.spend_categories ?? []
  )

  const [quantity, setQuantity] = useState<string>(
    lineItem?.quantity.toString() || ''
  )

  if (!lineItem) {
    return null
  }

  const packSize = lineItem.product.pack_size ?? 0
  const packSizeValue =
    ((lineItem.product.no_of_units ?? 0) > 1
      ? `${lineItem.product.no_of_units} x `
      : '') + packSize.toString()
  const packSizeUnit: string = lineItem.product.pack_size_unit ?? ''

  const handleSpendCategoryUpdate = (newSpendCategory: SpendCategory): void => {
    ReactGA.event('action_item', {
      item_list_id: 'basket_items',
      item_id: lineItem.id,
      action: 'update',
      changed_fields: ['spend_category']
    })

    const basketSpendCategoriesCopy = [...basketSpendCategories]

    const spendCategoryIndex = basketSpendCategoriesCopy.findIndex(
      c => c.field_id === newSpendCategory.field_id
    )

    if (spendCategoryIndex < 0) {
      basketSpendCategoriesCopy.push({ ...newSpendCategory })
    } else {
      basketSpendCategoriesCopy.splice(spendCategoryIndex, 1, {
        ...newSpendCategory
      })
    }

    setBasketSpendCategories(basketSpendCategoriesCopy)

    const lineItemToUpdate = { ...lineItem }
    lineItemToUpdate.spend_categories = basketSpendCategoriesCopy
    onUpdate(lineItemToUpdate)
  }

  const handleQuantityChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const newQuantity = parseInt(e.target.value, 10)

    if (isNaN(newQuantity)) {
      setQuantity('')
    } else if (newQuantity > 0) {
      setQuantity(newQuantity.toString())
      onQuantityChange(lineItem, newQuantity)
    }
  }

  return (
    <MaCard
      ref={ref}
      className={classNames(
        styles['basket-line-item'],
        { [styles.compact]: !fullView },
        className
      )}
      {...props}
    >
      <MaCard.Header className={styles.header}>
        <div className={styles['product-name']}>
          <MaCheckbox
            checked={selected}
            onCheckedChange={selected => {
              onItemSelectedChange(lineItem, selected)
            }}
          />

          <Link to={`/purchasing/products/${lineItem.product.id}`}>
            {lineItem.product?.description ?? lineItem.product?.description}
          </Link>
        </div>

        {isBusy && <Spinner size="sm" className={styles.remove} />}

        {!isBusy && (
          <CloseButton
            className={styles.remove}
            onClick={() => {
              onRemove(lineItem)
            }}
          />
        )}
      </MaCard.Header>

      <MaCard.Body className={styles.body}>
        <div className={styles.summary}>
          <div className={styles['img-wrapper']}>
            {lineItem.product.product_image_url && (
              <FallbackImage
                className={styles.img}
                src={lineItem.product.product_image_url ?? ''}
              />
            )}
            {!lineItem.product.product_image_url && lineItem.product.smiles && (
              <SmilesSvgRenderer
                width={88}
                height={88}
                smiles={lineItem.product?.smiles ?? ''}
                ErrorComponent={FallbackImage}
              />
            )}
          </div>

          <table>
            <tbody>
              <tr>
                <th>{t('common.product.part_number')}</th>
                <td>{lineItem.product.part_number}</td>
              </tr>
              <tr>
                <th>{t('common.product.pack_size')}</th>
                <td>
                  {t('common.product.pack_size_value', {
                    packSizeValue,
                    packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                      count: +packSize
                    })
                  })}
                </td>
              </tr>
              <tr>
                <th>{t('basket.line_item.price')}</th>
                <td>
                  {t('common.price', {
                    price,
                    currency: lineItem.currency ?? 'GBP'
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.controls}>
          <Form.Control
            type="number"
            min={1}
            value={quantity}
            className={styles.quantity}
            onChange={handleQuantityChange}
          />

          <div className={styles.subtotal}>
            <span className={styles['subtotal-label']}>
              {t('basket.line_item.subtotal')}
            </span>

            <span className={styles['subtotal-value']}>
              {t('common.price', {
                price: price * lineItem.quantity,
                currency: lineItem.currency ?? 'GBP'
              })}
            </span>
          </div>
        </div>
      </MaCard.Body>

      <MaCard.Footer className={styles.footer}>
        <ProductStockMessage
          className={styles['stock-info']}
          availability={
            stockInfo?.availability ?? ProductStockAvailabilityEnum.UNAVAILABLE
          }
          estimatedShippingDate={stockInfo?.estimated_shipping_date}
          isLoading={stockInfo === undefined}
          isSelfServe={!!lineItem.product.is_self_serve}
          leadTime={stockInfo?.lead_time}
          maximumQuantity={stockInfo?.maximum_quantity}
          minimumQuantity={stockInfo?.minimum_quantity}
          status={lineItem.product.status ?? ProductStatusEnum.INACTIVE}
        />

        <SpendCategoryFieldSelect
          selectedSpendCategories={basketSpendCategories}
          onValueChange={handleSpendCategoryUpdate}
          compactView={!fullView}
          allowEmpty={false}
          excludeInactive
        />
      </MaCard.Footer>
    </MaCard>
  )
}

export default BasketLineItemCard
