import { useContext } from 'react'
import { type Task } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import ReactGA from 'react-ga4'
import useApi from '../../common/hooks/useApi'
import { SortDirection } from '../../common/types/sort-direction'
import {
  QuickAccessContext,
  QuickAccessDispatchContext
} from '../../quick-access/components/QuickAccessContextProvider'
import { QuickAccessAction } from '../../quick-access/hooks/useQuickAccessState'
import useAccounts from '../../common/hooks/useAccounts'

interface UseTasksHook {
  tasks: Task[]
  search: string
  isLoading: boolean
  totalTasks: number
  sortingOrder: SortDirection
  changeSortingOrder: (sortingOrder: SortDirection) => void
  changeSearch: (search: string) => void
}

function useTasks (): UseTasksHook {
  const quickAccessState = useContext(QuickAccessContext)
  const quickAccessDispatch = useContext(QuickAccessDispatchContext)
  const { t } = useTranslation()

  const {
    tasksApi: { getPage },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()
  const { data: tasks, isLoading } = useSWR(
    activeAccount?.accountId ? ['tasks', activeAccount.accountId] : null,
    async () =>
      await fetcher(getPage, { size: 0, accountId: activeAccount?.accountId })
  )

  let filteredTasks: Task[] = tasks?.content ?? []
  const totalTasks = filteredTasks.length

  if (quickAccessState.tasksSearch) {
    filteredTasks = filteredTasks.filter((task: Task): boolean => {
      const {
        type,
        description,
        reference,
        created_by: createdBy,
        values
      } = task
      const searchSource = `${t(`task.card.title.${type}`)} ${
        description ?? ''
      } ${t(`task.card.text.${type}`, {
        created_by: createdBy?.name,
        ...values
      })}${reference ?? ''}${t('task.card.price', {
        po_value: values?.po_value
      })} ${values?.supplier_name ?? ''}`

      return searchSource
        .toLowerCase()
        .includes(quickAccessState.tasksSearch.toLowerCase())
    })
  }

  const sortedTasks = filteredTasks.sort((a: Task, b: Task): number => {
    if (a.created_date === b.created_date) return 0

    if (quickAccessState.tasksSortingOrder === SortDirection.ASC) {
      return a.created_date > b.created_date ? 1 : -1
    }

    return a.created_date > b.created_date ? -1 : 1
  })

  const changeSortingOrder = (sortingOrder: SortDirection): void => {
    ReactGA.event('sort_item_list', {
      item_list_id: 'tasks',
      sort_field: 'created_date',
      sort_order: sortingOrder
    })
    quickAccessDispatch({
      type: QuickAccessAction.CHANGE_TASKS_SORTING_ORDER,
      value: sortingOrder
    })
  }

  const changeSearch = (filter: string): void => {
    ReactGA.event('search_item_list', {
      item_list_id: 'tasks',
      search_term: 'tasks:' + filter
    })
    quickAccessDispatch({
      type: QuickAccessAction.CHANGE_TASKS_SEARCH,
      value: filter
    })
  }

  return {
    totalTasks,
    tasks: sortedTasks,
    search: quickAccessState.tasksSearch,
    sortingOrder: quickAccessState.tasksSortingOrder,
    changeSortingOrder,
    changeSearch,
    isLoading
  }
}

export default useTasks
