import useSWR from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import { type OrderRequests } from '@amici/myamici-api-client'
import { OrderRequestSearchType } from '../types/order-request-search-type'

interface UseOrderRequestHookParams {
  term: string
  page: number
  sort: string
  direction: string
  searchType: OrderRequestSearchType
  dateFrom?: string
  dateTo?: string
  facetFilter?: string[]
}

interface UseOrderRequestsHook {
  data?: OrderRequests
  isLoading: boolean
}

function useOrderRequests ({
  term,
  page,
  sort,
  direction,
  searchType,
  dateFrom,
  dateTo,
  facetFilter
}: UseOrderRequestHookParams): UseOrderRequestsHook {
  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''
  const includeClientPurchases =
    searchType === OrderRequestSearchType.AllOrderRequests

  const {
    orderRequestsApi: { getPage },
    fetcher
  } = useApi()

  const { data, isLoading } = useSWR<OrderRequests, Error>(
    accountId
      ? [
          'order-requests',
          term,
          page,
          sort,
          direction,
          dateFrom,
          dateTo,
          facetFilter,
          includeClientPurchases,
          accountId
        ]
      : null,
    async () =>
      await fetcher(getPage, {
        query: term,
        page,
        sort,
        direction,
        dateFrom,
        dateTo,
        facetFilter,
        includeClientPurchases,
        accountId
      })
  )

  return {
    data,
    isLoading
  }
}

export default useOrderRequests
