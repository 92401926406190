import useSWR from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  type OrderRequest,
  type OrderRequestsApiUpdateOrderRequestRequest,
  type OrderRequestResource,
  type OrderRequestsApiCancelOrderRequestRequest
} from '@amici/myamici-api-client'
import useSWRMutation from 'swr/mutation'

interface UseOrderRequestHook {
  data?: OrderRequestResource
  isLoading: boolean
  isUpdating: boolean
  isCancelling: boolean
  update: (
    orderRequest: OrderRequest,
  ) => Promise<OrderRequestResource | undefined>
  cancel: (reason: string) => Promise<void>
}

function useOrderRequest (orderRequestId: string = ''): UseOrderRequestHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequest,
      updateOrderRequest,
      cancelOrderRequest
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR<OrderRequestResource, Error>(
    accountId && orderRequestId
      ? ['order-request', orderRequestId, accountId]
      : null,
    async () => await fetcher(getOrderRequest, { orderRequestId, accountId })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation<
  OrderRequestResource,
  Error,
  ['order-request', string, string] | null,
  OrderRequestsApiUpdateOrderRequestRequest
  >(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }) => await fetcher(updateOrderRequest, arg),
    { populateCache: true, revalidate: false }
  )

  const { trigger: triggerCancel, isMutating: isCancelling } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiCancelOrderRequestRequest }) => {
      await fetcher(cancelOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const update = async (
    orderRequest: OrderRequest
  ): Promise<OrderRequestResource | undefined> => {
    if (!accountId || !orderRequestId) {
      return
    }

    return await triggerUpdate({ orderRequest, orderRequestId, accountId })
  }

  const cancel = async (reason: string): Promise<void> => {
    if (!accountId || !orderRequestId || !reason) {
      return
    }

    await triggerCancel({
      cancellationReason: { reason },
      orderRequestId,
      accountId
    })
  }

  return {
    data,
    update,
    cancel,
    isLoading,
    isUpdating,
    isCancelling
  }
}

export default useOrderRequest
