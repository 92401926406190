import { useRef, type ReactElement } from 'react'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import MaConfirm from '../../common/components/MaConfirm'
import styles from '../assets/scss/OrderRequest.module.scss'
import { type OrderRequest } from '@amici/myamici-api-client'

interface OrderRequestCancelSummaryModalProps {
  show: boolean
  orderRequest: OrderRequest
  onClose: () => void
}

function OrderRequestCancelSummaryModal ({
  show,
  orderRequest,
  onClose
}: OrderRequestCancelSummaryModalProps): ReactElement {
  const { t } = useTranslation()
  const { cancel, isCancelling } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const modalRef = useRef<any>(null)
  const maxLength = 500

  const {
    register,
    getValues,
    reset,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      reason: ''
    },
    values: {
      reason: ''
    }
  })

  const handleClose = (): void => {
    if (isCancelling) {
      return
    }
    reset()
    onClose()
  }

  const handleCancel = async (): Promise<void> => {
    if (isCancelling) {
      return
    }

    await cancel(getValues('reason')?.trim())
    await refreshHistory()

    handleClose()
  }

  return (
    <MaConfirm
      ref={modalRef}
      size="lg"
      show={show}
      disabled={isCancelling || !isValid}
      title={t('order_request.cancel_form.title', {
        ref: orderRequest.order_ref
      })}
      confirmLabel={t('common.button.labels.cancel')}
      closeLabel={t('common.button.labels.close')}
      onClose={handleClose}
      onConfirm={handleCancel}
    >
      <Form className={styles['order-request-modal-form']}>
        <fieldset disabled={isCancelling}>
          <Row>
            <FormGroup as={Col} lg="12" controlId="reason">
              <Form.Label>
                {t('order_request.form.label.cancel_reason')}
              </Form.Label>
              <Form.Control
                as="textarea"
                className={styles.reason}
                {...register('reason', {
                  required: {
                    value: true,
                    message: t(
                      'validation.error.order_request.required.cancel_reason'
                    )
                  }
                })}
                maxLength={maxLength}
                isInvalid={!!errors.reason}
                title={errors.reason?.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.reason?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestCancelSummaryModal
