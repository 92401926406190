import { type HTMLAttributes, type ReactElement } from 'react'
import ReactGA from 'react-ga4'
import { type ProductResource } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import useProductDetailsTabs from '../hooks/useProductDetailsTabs'
import MaDetailsTabs from '../../common/components/MaDetailsTabs'
import styles from '../assets/scss/ProductDetails.module.scss'

interface ProductDetailsTabsProps extends HTMLAttributes<HTMLDivElement> {
  product: ProductResource
}

function ProductDetailsTabs ({
  product
}: ProductDetailsTabsProps): ReactElement {
  const { t } = useTranslation()
  const tabs = useProductDetailsTabs(product)

  const activeTab = location.hash.substring(1) || tabs[0].key

  const setActiveTab = (tab: string): void => {
    ReactGA.event('close_component', {
      component_type: 'tab',
      component_id: 'product.' + activeTab
    })

    if (tab != null) {
      ReactGA.event('open_component', {
        component_type: 'tab',
        component_id: 'product.' + tab
      })
    }

    location.hash = tab ?? ''
  }

  return (
    <>
      <h4 className={styles['section-title']}>
        {t('product.details.additional_details')}
      </h4>

      <MaDetailsTabs
        tabs={tabs}
        activeTab={activeTab}
        onSelectTab={setActiveTab}
      />
    </>
  )
}

export default ProductDetailsTabs
