import { useMemo, type ReactElement } from 'react'
import { BsPencil } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { type OrderRequest } from '@amici/myamici-api-client'
import formatAddress from '../../common/utils/format-address'
import useAccounts from '../../common/hooks/useAccounts'
import MaDescriptionList from '../../common/components/MaDescriptionList'
import MaActionIcon from '../../common/components/MaActionIcon'
import styles from '../assets/scss/OrderRequest.module.scss'

function OrderRequestDeliveryInfo ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  const { t } = useTranslation()
  const { accountProfile } = useAccounts()

  const address = accountProfile?.client?.addresses?.find(
    address => address.id === orderRequest.address_id.toString()
  )
  const noteToSupplier = orderRequest.note_to_supplier

  const infoEntries = useMemo(
    () => [
      ...(address
        ? [
            {
              term: t('order_request.delivery_info.deliver_to'),
              details: formatAddress(address)
            }
          ]
        : []),
      ...(noteToSupplier
        ? [
            {
              term: t('order_request.delivery_info.note_to_supplier'),
              details: orderRequest.note_to_supplier
            },
            {
              term: '',
              details: (
                <span className={styles.warning}>
                  {t('order_request.delivery_info.warning_message')}
                </span>
              )
            }
          ]
        : [])
    ],
    [address, noteToSupplier, orderRequest.note_to_supplier, t]
  )

  const handleEditClick = (): void => {}

  return (
    <div className={styles['delivery-info']}>
      <MaDescriptionList className={styles.list} items={infoEntries} />

      <div className={styles.actions}>
        <MaActionIcon
          title={t('order_request.action.line_item.edit')}
          className={styles['action-btn']}
          onClick={handleEditClick}
        >
          <BsPencil size={16} />
        </MaActionIcon>
      </div>
    </div>
  )
}

export default OrderRequestDeliveryInfo
