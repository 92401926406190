import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { type SpendCategory } from '@amici/myamici-api-client'
import { Link } from 'react-router-dom'
import MaPageTitle from '../../common/components/MaPageTitle'
import MaPanel, { MaPanelHeader } from '../../common/components/MaPanel'
import { useEnv } from '../../common/hooks/useEnv'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'
import useAccounts from '../../common/hooks/useAccounts'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaToast from '../../common/components/MaToast'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import useIsMobile from '../../common/hooks/useIsMobile'
import styles from '../assets/scss/UserAccount.module.scss'

function UserAccount (): ReactElement {
  const { t } = useTranslation()
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const MA3_BASE_URL: string = REACT_APP_MA3_BASE_URL ?? ''
  const { accountProfile, refreshAccountProfile, isRefreshingAccountProfile } =
    useAccounts()
  const { showToast, closeToast } = useToastNotification()
  const { handleSetAccountDefault, isSettingAccountDefault, groupNames } =
    useSpendCategories(true)
  const isMobile = useIsMobile()

  let isBusy = isRefreshingAccountProfile || isSettingAccountDefault

  const handleSpendCategorySelect = async (
    newSpendCategory: SpendCategory
  ): Promise<void> => {
    try {
      await handleSetAccountDefault(newSpendCategory)
      await refreshAccountProfile()
    } catch (error) {
      const toastId = Date.now()
      showToast(
        toastId,
        <MaToast
          type="danger"
          onClose={() => {
            closeToast(toastId)
          }}
        >
          <p>{t('account.update.error')}</p>
        </MaToast>
      )
    } finally {
      isBusy = false
    }
  }

  const accountSettings = [
    {
      title: 'user_account.link.my_account',
      path: `${MA3_BASE_URL}/MyAccount/MyUserAccount.aspx`
    },
    {
      title: 'user_account.link.addresses',
      path: `${MA3_BASE_URL}/MyAccount/MyUserAddresses.aspx`
    },
    {
      title: 'user_account.link.preferences',
      path: `${MA3_BASE_URL}/MyAccount/MyUserSettings.aspx`
    }
  ]

  return (
    <Container fluid="auto" className="ma-page">
      <MaPageTitle>{t('user_account.title')}</MaPageTitle>

      <div className={styles.content}>
        <Row>
          <Col xs={12}>
            <MaPanel>
              <MaPanel.Body>
                <div className={styles['account-settings']}>
                  {accountSettings.map(setting => (
                    <Link
                      to={setting.path}
                      aria-label={`setting-link-${setting.title}`}
                      target="_blank"
                      key={setting.title}
                    >
                      {t(setting.title)}
                    </Link>
                  ))}
                </div>
              </MaPanel.Body>
            </MaPanel>
          </Col>
        </Row>
        {groupNames.length > 0 && (
          <div>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col xs={12}>
                <MaPanel>
                  <MaPanelHeader className={styles.header}>
                    <div className={styles.data}>
                      <h4>Default order spend category values</h4>
                    </div>
                  </MaPanelHeader>
                  <MaPanel.Body>
                    <SpendCategoryFieldSelect
                      selectedSpendCategories={
                        accountProfile?.default_spend_categories ?? []
                      }
                      isBusy={isBusy}
                      allowEmpty={true}
                      compactView={isMobile}
                      onValueChange={spendCategory => {
                        void handleSpendCategorySelect(spendCategory)
                      }}
                      excludeInactive={true}
                    />
                  </MaPanel.Body>
                </MaPanel>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Container>
  )
}

export default UserAccount
