import useSWRImmutable from 'swr'
import { type DateRange } from 'react-day-picker'
import { format } from 'date-fns'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import { OrderRequestSearchType } from '../types/order-request-search-type'

interface UseOrderRequestFacetsHook {
  isLoading: boolean
  initialFacets: Record<string, Record<string, number>>
  availableFacets: Record<string, Record<string, number>>
}

function useOrderRequestFacets (
  term: string,
  orderRequestSearchType: OrderRequestSearchType,
  facetFilter: string[],
  orderDateRange?: DateRange
): UseOrderRequestFacetsHook {
  const { activeAccount } = useAccounts()
  const { orderRequestsApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const startDate =
    orderDateRange?.from && format(orderDateRange?.from, 'yyyy-MM-dd')
  const endDate = orderDateRange?.to && format(orderDateRange?.to, 'yyyy-MM-dd')

  // Keeping track of the available facets w/o any filters applied
  const { data: initialFacetsData } = useSWRImmutable(
    accountId
      ? [
          'initial-order-request-search-facets',
          term,
          orderRequestSearchType,
          startDate,
          endDate,
          accountId
        ]
      : null,
    async () =>
      await fetcher(orderRequestsApi.getPage, {
        query: term,
        ...(startDate && { dateFrom: startDate }),
        ...(endDate && { dateTo: endDate }),
        returnFacets: true,
        returnResults: false,
        includeClientPurchases:
          orderRequestSearchType === OrderRequestSearchType.AllOrderRequests,
        accountId
      })
  )

  const { data, isLoading } = useSWRImmutable(
    initialFacetsData && accountId
      ? [
          'available-order-request-search-facets',
          term,
          orderRequestSearchType,
          facetFilter,
          startDate,
          endDate,
          accountId
        ]
      : null,
    async () =>
      await fetcher(orderRequestsApi.getPage, {
        query: term,
        ...(startDate && { dateFrom: startDate }),
        ...(endDate && { dateTo: endDate }),
        ...(facetFilter.length && { facetFilter }),
        returnFacets: true,
        returnResults: false,
        includeClientPurchases:
          orderRequestSearchType === OrderRequestSearchType.AllOrderRequests,
        accountId
      })
  )

  return {
    isLoading,
    initialFacets: initialFacetsData?.facets ?? {},
    availableFacets: data?.facets ?? {}
  }
}

export default useOrderRequestFacets
