import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BsGrid, BsList, BsPlus } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import { type ViewType } from '../types/order-request-details-summary-view-type'
import MaActionIcon from '../../common/components/MaActionIcon'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import ToggleButtonGroup from '../../common/components/ToggleButtonGroup'
import styles from '../assets/scss/OrderRequest.module.scss'

export interface OrderRequestSpendPanelHeaderControlsProps {
  activeView: ViewType
  compactView: boolean
  allCollapsed?: boolean
  onExpandAll: () => void
  onCollapseAll: () => void
  onViewChange: (view: ViewType) => void
  onAddNew?: () => void
}

function OrderRequestSpendPanelHeaderControls ({
  activeView = 'grouped',
  compactView,
  allCollapsed,
  onExpandAll,
  onCollapseAll,
  onViewChange,
  onAddNew
}: OrderRequestSpendPanelHeaderControlsProps): ReactElement {
  const { t } = useTranslation()

  const handleChangeView = (): void => {
    onViewChange(activeView === 'grouped' ? 'table' : 'grouped')
  }

  const handleToggleCollapsed = (): void => {
    allCollapsed ? onExpandAll() : onCollapseAll()
  }

  if (compactView) {
    return (
      <MaActionMenu>
        {allCollapsed && (
          <MaActionMenuItem onClick={onExpandAll}>
            {t('common.button.labels.expand_all')}
          </MaActionMenuItem>
        )}
        {!allCollapsed && (
          <MaActionMenuItem onClick={onCollapseAll}>
            {t('common.button.labels.collapse_all')}
          </MaActionMenuItem>
        )}
        <MaActionMenuItem onClick={onAddNew}>
          {t('order_request.action.line_item.add')}
        </MaActionMenuItem>
      </MaActionMenu>
    )
  }

  return (
    <div className={styles.controls}>
      {(compactView || activeView === 'grouped') && (
        <Button
          variant="outline-primary"
          className={classNames(styles['expand-collapse-btn'], 'rounded')}
          onClick={handleToggleCollapsed}
        >
          {allCollapsed
            ? t('common.button.labels.expand_all')
            : t('common.button.labels.collapse_all')}
        </Button>
      )}

      <ToggleButtonGroup
        onClick={handleChangeView}
        selectedBtnIndex={activeView === 'grouped' ? 0 : 1}
      >
        <BsGrid size={24} title={t('common.view.grouped')} />
        <BsList size={24} title={t('common.view.table')} />
      </ToggleButtonGroup>

      <MaActionIcon
        className={styles['add-btn']}
        title={t('common.button.labels.add_new')}
        onClick={onAddNew}
      >
        <BsPlus size={32} />
      </MaActionIcon>
    </div>
  )
}

export default OrderRequestSpendPanelHeaderControls
