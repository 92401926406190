import { type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { type BasketLineItem } from '@amici/myamici-api-client'
import useOrderRequestSummary from '../../order-requests/hooks/useOrderRequestSummary'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/Basket.module.scss'

function OrderRequestSummaryBreakdown ({
  lineItems
}: {
  lineItems: BasketLineItem[]
}): ReactElement {
  const { t } = useTranslation()
  const {
    currencies,
    getCurrencySuppliers,
    getSupplierItems,
    getSupplierTotal,
    getSupplierSurcharges,
    getSupplierSubtotal,
    getTotalItemCount,
    getTotalSurcharges,
    getItemsTotal,
    getTotal
  } = useOrderRequestSummary(lineItems)

  return (
    <div className={styles['summary-breakdown']}>
      <h5>
        <Trans
          i18nKey="order_request.form.summary.title"
          values={{ count: currencies.length }}
          components={[<span key="summaries-count">{currencies.length}</span>]}
        />
      </h5>

      {currencies.map(currency => (
        <MaPanel key={currency} className={styles['summary-breakdown-panel']}>
          {getCurrencySuppliers(currency).map(supplier => (
            <section
              key={`${currency}-${supplier.id}`}
              className={styles['summary-breakdown-section']}
            >
              <h6>{supplier.name}</h6>

              <div className={styles['summary-breakdown-totals']}>
                <p>
                  <span>
                    {t('order_request.form.summary.item', {
                      count: getSupplierItems(supplier, currency).length
                    })}
                  </span>

                  <span>
                    {t('common.price', {
                      price: getSupplierTotal(supplier, currency),
                      currency
                    })}
                  </span>
                </p>

                <p>
                  <span>{t('order_request.form.summary.est_surcharges')}</span>

                  <span>
                    {getSupplierSurcharges(supplier, currency) >= 0
                      ? t('common.price', {
                        price: getSupplierSurcharges(supplier, currency),
                        currency
                      })
                      : '-'}
                  </span>
                </p>

                <p>
                  <strong>
                    {t('order_request.form.summary.supplier_subtotal')}
                  </strong>

                  <strong>
                    {t('common.price', {
                      price: getSupplierSubtotal(supplier, currency),
                      currency
                    })}
                  </strong>
                </p>
              </div>
            </section>
          ))}

          <section className={styles['summary-breakdown-total']}>
            <h6>{t('order_request.form.summary.total.spend')}</h6>

            <div>
              <p>
                <span>
                  {t('order_request.form.summary.item', {
                    count: getTotalItemCount(currency)
                  })}
                </span>

                <span>
                  {t('common.price', {
                    price: getItemsTotal(currency),
                    currency
                  })}
                </span>
              </p>

              <p>
                <span>
                  {t('order_request.form.summary.total.est_surcharges')}
                </span>

                <span>
                  {t('common.price', {
                    price: getTotalSurcharges(currency),
                    currency
                  })}
                </span>
              </p>

              <p>
                <strong>{t('order_request.form.summary.total.spend')}</strong>

                <strong>
                  {t('common.price', {
                    price: getTotal(currency),
                    currency
                  })}
                </strong>
              </p>
            </div>
          </section>
        </MaPanel>
      ))}
    </div>
  )
}

export default OrderRequestSummaryBreakdown
