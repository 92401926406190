import { type ReactNode, type ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { format, isValid, parseISO } from 'date-fns'
import {
  type OrderRequestHistoryEntry,
  OrderRequestHistoryEntryAllOfEventTypeEnum,
  type OrderRequest
} from '@amici/myamici-api-client'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/OrderRequest.module.scss'

function getEditOrderRequestDescriptionKey (
  oldValuePresent: boolean,
  newValuePresent: boolean
): string {
  let editType = 'updated'
  if (oldValuePresent && !newValuePresent) editType = 'removed'
  if (!oldValuePresent && newValuePresent) editType = 'added'
  return `order_request.history.edit_order_request.${editType}`
}

function EventEntry ({ entry }: { entry: OrderRequestHistoryEntry }): ReactNode {
  switch (entry.event_type) {
    case OrderRequestHistoryEntryAllOfEventTypeEnum.CREATE_ORDER_REQUEST:
      return (
        <div className={styles['history-event']}>
          <Trans
            i18nKey={`order_request.history.${entry.event_type}`}
            values={{
              username: entry.username,
              date: format(new Date(entry.created), 'd MMM yyyy'),
              time: format(new Date(entry.created), 'HH:mm'),
              oldValues: entry.old_values,
              newValues: entry.new_values
            }}
          />
        </div>
      )

    case OrderRequestHistoryEntryAllOfEventTypeEnum.EDIT_ORDER_REQUEST:
      return (
        <>
          {Object.entries(entry.new_values ?? {}).map(
            ([field, newValue]) =>
              entry.old_values &&
              entry.old_values[field] !== newValue && (
                <div key={field} className={styles['history-event']}>
                  <div>
                    <Trans
                      i18nKey={`order_request.history.${entry.event_type}.${field}`}
                      values={{
                        username: entry.username,
                        date: format(new Date(entry.created), 'd MMM yyyy'),
                        time: format(new Date(entry.created), 'HH:mm')
                      }}
                    />
                  </div>
                  <div>
                    <Trans
                      i18nKey={getEditOrderRequestDescriptionKey(
                        !!entry.old_values[field],
                        !!newValue
                      )}
                      values={{
                        old_value: isValid(
                          parseISO(
                            entry.old_values[field] as unknown as string
                          )
                        )
                          ? format(
                            parseISO(
                              entry.old_values[field] as unknown as string
                            ),
                            'd MMM yyyy'
                          )
                          : entry.old_values[field],
                        new_value: isValid(
                          parseISO(newValue as unknown as string)
                        )
                          ? format(
                            parseISO(newValue as unknown as string),
                            'd MMM yyyy'
                          )
                          : newValue
                      }}
                    />
                  </div>
                </div>
              )
          )}
        </>
      )

    case OrderRequestHistoryEntryAllOfEventTypeEnum.EDIT_ORDER_REQUEST_LINE_ITEM:
      return (
        <>
          {Object.entries(entry.new_values ?? {}).map(
            ([field, newValue]) =>
              entry.old_values &&
              entry.old_values[field] !== newValue && (
                <div key={field} className={styles['history-event']}>
                  <div>
                    <Trans
                      i18nKey={`order_request.history.${entry.event_type}.${field}`}
                      values={{
                        username: entry.username,
                        date: format(new Date(entry.created), 'd MMM yyyy'),
                        time: format(new Date(entry.created), 'HH:mm'),
                        productName: entry.old_values.product_name,
                        fieldName: entry.old_values.spend_category_field_name,
                        fromPath:
                          entry.old_values.spend_category_path.length > 0
                            ? 'from'
                            : '',
                        oldValues: entry.old_values,
                        newValues: entry.new_values
                      }}
                    />
                  </div>
                </div>
              )
          )}
        </>
      )

    case OrderRequestHistoryEntryAllOfEventTypeEnum.REMOVE_ORDER_REQUEST_LINE_ITEM:
      return (
        <div className={styles['history-event']}>
          <Trans
            i18nKey={`order_request.history.${entry.event_type}`}
            values={{
              username: entry.username,
              date: format(new Date(entry.created), 'd MMM yyyy'),
              time: format(new Date(entry.created), 'HH:mm'),
              oldValues: entry.old_values
            }}
          />
        </div>
      )

    case OrderRequestHistoryEntryAllOfEventTypeEnum.CANCEL_ORDER_REQUEST:
      return (
        <div className={styles['history-event']}>
          <Trans
            i18nKey={`order_request.history.${entry.event_type}`}
            values={{
              username: entry.username,
              date: format(new Date(entry.created), 'd MMM yyyy'),
              time: format(new Date(entry.created), 'HH:mm'),
              oldValues: entry.old_values,
              newValues: entry.new_values
            }}
          />
        </div>
      )
  }

  return null
}

function OrderRequestHistory ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  const { historyEntries, isLoading } = useOrderRequestHistory(orderRequest.id)

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {historyEntries.map(entry => (
        <EventEntry entry={entry} key={entry.id} />
      ))}
    </>
  )
}

export default OrderRequestHistory
