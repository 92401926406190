import { type FormEvent, type ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import { OrderRequestSearchType } from '../types/order-request-search-type'
import MaPageTitle from '../../common/components/MaPageTitle'
import OrderRequestSearchForm from './OrderRequestSearchForm'
import styles from '../assets/scss/OrderRequest.module.scss'

export const ORDER_REQUESTS_PAGE_PATH = '/purchasing/order-requests'

function OrderRequestHeader (): ReactElement {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState<OrderRequestSearchType>(
    OrderRequestSearchType.MyOrderRequests
  )

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchTerm.trim()) {
      setSearchTerm('')
      return
    }

    navigate(
      `${ORDER_REQUESTS_PAGE_PATH}?term=${searchTerm.trim()}&searchType=${searchType}`
    )
  }

  return (
    <div className={styles.header}>
      <MaPageTitle>{t('order_requests.title')}</MaPageTitle>

      <Row>
        <Col sm={12} md={8} xl={6}>
          <OrderRequestSearchForm
            search={searchTerm}
            type={searchType}
            onSearchChange={e => {
              setSearchTerm(e.target.value)
            }}
            onTypeChange={setSearchType}
            onSubmit={handleSearchSubmit}
          />
        </Col>
      </Row>
    </div>
  )
}

export default OrderRequestHeader
