import { type ReactElement, type ReactNode, createContext } from 'react'
import useQuickAccessState, {
  type QuickAccessAction,
  quickAccessState
} from '../hooks/useQuickAccessState'

export const QuickAccessContext = createContext(quickAccessState)
export const QuickAccessDispatchContext = createContext(
  (action: { type: QuickAccessAction, value: string }) => {}
)

function QuickAccessContextProvider ({
  children
}: {
  children: ReactNode
}): ReactElement {
  const { state, dispatch } = useQuickAccessState()

  return (
    <QuickAccessContext.Provider value={state}>
      <QuickAccessDispatchContext.Provider value={dispatch}>
        {children}
      </QuickAccessDispatchContext.Provider>
    </QuickAccessContext.Provider>
  )
}

export default QuickAccessContextProvider
