import { type ReactElement } from 'react'
import type MaTab from '../models/MaTab'
import useIsMobile from '../hooks/useIsMobile'
import MaAccordion from './MaAccordion'
import MaVerticalTabs from './MaVerticalTabs'

interface MaDetailsTabsProps {
  tabs: MaTab[]
  activeTab: string
  onSelectTab?: (tabKey: string) => void
}

function MaDetailsTabs ({
  tabs,
  activeTab,
  onSelectTab
}: MaDetailsTabsProps): ReactElement {
  const isMobile = useIsMobile()

  return isMobile
    ? (
    <MaAccordion tabs={tabs} activeTab={activeTab} onSelectTab={onSelectTab} />
      )
    : (
    <MaVerticalTabs
      tabs={tabs}
      activeTab={activeTab}
      onSelectTab={onSelectTab}
    />
      )
}

export default MaDetailsTabs
