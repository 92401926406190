import { type Dispatch, useReducer } from 'react'
import type { FacetFilters } from '../../purchasing/types/facet-filters'

export interface OrderRequestsPageState {
  term: string
  filters: FacetFilters
  filtersUpdated: boolean
  showOffcanvasFilters: boolean
}

export enum OrderRequestsPageAction {
  UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  OPEN_OFFCANVAS_FILTERS = 'OPEN_OFFCANVAS_FILTERS',
  CLOSE_OFFCANVAS_FILTERS = 'CLOSE_OFFCANVAS_FILTERS',
  SET_STATE = 'SET_STATE',
  PATCH_STATE = 'PATCH_STATE',
  RESET_STATE = 'RESET_STATE',
}

function reducer (
  state: OrderRequestsPageState,
  action: { type: OrderRequestsPageAction, value?: any }
): OrderRequestsPageState {
  switch (action.type) {
    case OrderRequestsPageAction.UPDATE_SEARCH_TERM: {
      return {
        ...state,
        term: action.value
      }
    }
    case OrderRequestsPageAction.UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.value,
        filtersUpdated: true
      }
    }
    case OrderRequestsPageAction.CLEAR_FILTERS: {
      return {
        ...state,
        filters: new Map(),
        filtersUpdated: false
      }
    }
    case OrderRequestsPageAction.OPEN_OFFCANVAS_FILTERS: {
      return {
        ...state,
        showOffcanvasFilters: true
      }
    }
    case OrderRequestsPageAction.CLOSE_OFFCANVAS_FILTERS: {
      return {
        ...state,
        showOffcanvasFilters: false
      }
    }
    case OrderRequestsPageAction.SET_STATE: {
      return {
        ...action.value
      }
    }
    case OrderRequestsPageAction.PATCH_STATE: {
      return {
        ...state,
        ...action.value
      }
    }
    case OrderRequestsPageAction.RESET_STATE: {
      return {
        term: '',
        filters: new Map(),
        filtersUpdated: false,
        showOffcanvasFilters: false
      }
    }
    default: {
      return state
    }
  }
}

function useOrderRequestsPageState (state: OrderRequestsPageState): {
  pageState: OrderRequestsPageState
  dispatch: Dispatch<{
    type: OrderRequestsPageAction
    value?: any
  }>
} {
  const [pageState, dispatch] = useReducer(reducer, state)

  return {
    pageState,
    dispatch
  }
}

export default useOrderRequestsPageState
