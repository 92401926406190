import { type Dispatch, useReducer } from 'react'
import { ProductSearchType } from '../types/product-search-type'
import { type FacetFilters } from '../types/facet-filters'
import ReactGA from 'react-ga4'

export interface ProductsPageState {
  term: string
  type: ProductSearchType
  filters: FacetFilters
  filtersUpdated: boolean
  showOffcanvasFilters: boolean
}

export enum ProductsPageAction {
  UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM',
  UPDATE_SEARCH_TYPE = 'UPDATE_SEARCH_TYPE',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  RESET_FILTERS_UPDATED_FLAG = 'RESET_FILTERS_UPDATED_FLAG',
  OPEN_OFFCANVAS_FILTERS = 'OPEN_OFFCANVAS_FILTERS',
  CLOSE_OFFCANVAS_FILTERS = 'CLOSE_OFFCANVAS_FILTERS',
  SET_STATE = 'SET_STATE',
  PATCH_STATE = 'PATCH_STATE',
  RESET_STATE = 'RESET_STATE',
}

function reducer (
  state: ProductsPageState,
  action: { type: ProductsPageAction, value?: any }
): ProductsPageState {
  switch (action.type) {
    case ProductsPageAction.UPDATE_SEARCH_TERM: {
      return {
        ...state,
        term: action.value
      }
    }
    case ProductsPageAction.UPDATE_SEARCH_TYPE: {
      return {
        ...state,
        type: action.value
      }
    }
    case ProductsPageAction.UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.value,
        filtersUpdated: true
      }
    }
    case ProductsPageAction.CLEAR_FILTERS: {
      ReactGA.event('clear_search_filters')
      return {
        ...state,
        filters: new Map(),
        filtersUpdated: false
      }
    }
    case ProductsPageAction.RESET_FILTERS_UPDATED_FLAG: {
      ReactGA.event('reset_search_filters')
      return {
        ...state,
        filtersUpdated: false
      }
    }
    case ProductsPageAction.OPEN_OFFCANVAS_FILTERS: {
      ReactGA.event('open_component', {
        component_type: 'offcanvas',
        component_id: 'search_filters'
      })
      return {
        ...state,
        showOffcanvasFilters: true
      }
    }
    case ProductsPageAction.CLOSE_OFFCANVAS_FILTERS: {
      ReactGA.event('close_component', {
        component_type: 'offcanvas',
        component_id: 'search_filters'
      })
      return {
        ...state,
        showOffcanvasFilters: false
      }
    }
    case ProductsPageAction.SET_STATE: {
      return {
        ...action.value
      }
    }
    case ProductsPageAction.PATCH_STATE: {
      return {
        ...state,
        ...action.value
      }
    }
    case ProductsPageAction.RESET_STATE: {
      return {
        term: '',
        type: ProductSearchType.Keyword,
        filters: new Map(),
        filtersUpdated: false,
        showOffcanvasFilters: false
      }
    }
    default: {
      return state
    }
  }
}

function useProductsPageState (state: ProductsPageState): {
  productsPageState: ProductsPageState
  dispatch: Dispatch<{
    type: ProductsPageAction
    value?: any
  }>
} {
  const [productsPageState, dispatch] = useReducer(reducer, state)

  return {
    productsPageState,
    dispatch
  }
}

export default useProductsPageState
