import { type FormEvent, useRef, useState, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  OrderRequestBasePriorityEnum,
  type BasketLineItem,
  type OrderRequestResource
} from '@amici/myamici-api-client'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'
import { Controller, useForm } from 'react-hook-form'
import { formatISO } from 'date-fns'
import MaConfirm from '../../common/components/MaConfirm'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import MaDatePicker from '../../common/components/MaDatePicker'
import MaCheckbox from '../../common/components/MaCheckbox'
import useAccounts from '../../common/hooks/useAccounts'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import useCreateOrderRequest from '../../order-requests/hooks/useCreateOrderRequest'
import useOrderRequestSummary from '../../order-requests/hooks/useOrderRequestSummary'
import formatAddress from '../../common/utils/format-address'
import OrderRequestSummaryBreakdown from './OrderRequestSummaryBreakdown'
import styles from '../assets/scss/Basket.module.scss'

export interface OrderRequestFormModalProps {
  lineItems: BasketLineItem[]
  onConfirm: (orderRequests?: Array<OrderRequestResource | undefined>) => void
  onClose: () => void
}

function OrderRequestFormModal ({
  lineItems,
  onConfirm,
  onClose
}: OrderRequestFormModalProps): ReactElement {
  const { t } = useTranslation()
  const { accountProfile } = useAccounts()
  const { allAccounts, isLoading: isLoadingAllAccounts } = useAllAccounts()
  const { isSubmitting, createOrderRequest } = useCreateOrderRequest()
  const { currencies } = useOrderRequestSummary(lineItems)
  const [modalFullyVisible, setModalFullyVisible] = useState(false)
  const [defaultAddressChanged, setDefaultAddressChanged] = useState(false)
  const modalRef = useRef<any>(null)

  const datepickerContainer = modalRef?.current?.dialog

  const defaultAddressId =
    accountProfile?.default_address_id ||
    accountProfile?.client?.default_address_id ||
    ''

  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      requestedBy: accountProfile?.id,
      requiredByDate: '',
      priority: OrderRequestBasePriorityEnum.NONE,
      fao: accountProfile?.name ?? '',
      addressId: defaultAddressId,
      confidential: false,
      reason: ''
    },
    values: {
      requestedBy: accountProfile?.id,
      requiredByDate: '',
      priority: OrderRequestBasePriorityEnum.NONE,
      fao: accountProfile?.name ?? '',
      addressId: defaultAddressId,
      confidential: false,
      reason: ''
    }
  })

  const show = lineItems?.length > 0

  const isBusy = isLoadingAllAccounts || isSubmitting

  const handleClose = (): void => {
    if (isSubmitting) {
      return
    }

    setDefaultAddressChanged(false)
    setModalFullyVisible(false)
    reset()
    onClose()
  }

  const handleRequestedByChange = (
    onChange: (...event: any[]) => void,
    value: string
  ): void => {
    onChange(value)

    const requestedByAccount = allAccounts?.content?.find(
      account => account.id === value
    )

    const oldAddressId = getValues('addressId')
    const newAddressId =
      (requestedByAccount?.default_address_id !== '0' &&
        requestedByAccount?.default_address_id) ||
      (accountProfile?.client?.default_address_id ?? '')

    if (requestedByAccount && newAddressId) {
      setValue('addressId', newAddressId)
      setValue('fao', requestedByAccount.name)
    }

    setDefaultAddressChanged(!!oldAddressId && newAddressId !== oldAddressId)

    void trigger()
  }

  const handleAddressChange = (
    onChange: (...event: any[]) => void,
    value: string
  ): void => {
    setDefaultAddressChanged(false)
    onChange(value)
  }

  const handleCreateOrderRequests = async (): Promise<void> => {
    const requestedByAccount = allAccounts?.content?.find(
      account => account.id === getValues('requestedBy')
    )

    if (!requestedByAccount) {
      return
    }

    const requests = currencies.map(
      async currency =>
        await createOrderRequest({
          requested_by: requestedByAccount,
          required_by_date: getValues('requiredByDate') || null,
          priority: getValues('priority'),
          address_id: parseInt(getValues('addressId'), 10),
          fao: getValues('fao').trim(),
          confidential: getValues('confidential'),
          reason: getValues('reason').trim(),
          line_item_ids: lineItems
            .filter(lineItem => lineItem.line_item.currency === currency)
            .map(lineItem => lineItem.line_item.id)
        })
    )

    const createdOrderRequests = await Promise.all(requests)

    onConfirm(createdOrderRequests)
  }

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await handleCreateOrderRequests()
  }

  return (
    <MaConfirm
      ref={modalRef}
      show={show}
      size="lg"
      disabled={isBusy || !isValid}
      title={t('order_request.form.title')}
      closeLabel={t('common.button.labels.cancel')}
      confirmLabel={t('order_request.form.label.create_and_view')}
      onShow={() => {
        setModalFullyVisible(true)
      }}
      onConfirm={handleCreateOrderRequests}
      onClose={handleClose}
    >
      <Form
        className={styles['order-request-form']}
        onSubmit={e => {
          void handleSubmit(e)
        }}
      >
        <fieldset disabled={isBusy}>
          <Row>
            <FormGroup as={Col} lg="4" controlId="requested-by">
              <Form.Label>
                {t('order_request.form.label.requested_by')}
              </Form.Label>
              <Controller
                name="requestedBy"
                control={control}
                rules={{
                  validate: v => !!v
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid }
                }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.requested_by')}
                    value={value}
                    isInvalid={invalid}
                    onValueChange={value => {
                      handleRequestedByChange(onChange, value)
                    }}
                  >
                    {allAccounts?.content?.map(account => (
                      <MaSelectItem key={account.id} value={account.id}>
                        {account.name}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="required-by-date">
              <Form.Label>
                {t('order_request.form.label.date_required')}
              </Form.Label>
              {modalFullyVisible && (
                <MaDatePicker
                  container={datepickerContainer}
                  selected={
                    watch('requiredByDate')
                      ? new Date(getValues('requiredByDate'))
                      : undefined
                  }
                  onSelect={day => {
                    setValue(
                      'requiredByDate',
                      day ? formatISO(day, { representation: 'date' }) : ''
                    )
                  }}
                />
              )}
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="4" controlId="priority">
              <Form.Label>{t('order_request.form.label.priority')}</Form.Label>
              <Controller
                name="priority"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.priority')}
                    onValueChange={value => {
                      onChange(value)
                    }}
                    value={value}
                  >
                    {Object.keys(OrderRequestBasePriorityEnum).map(key => (
                      <MaSelectItem
                        key={key}
                        value={
                          OrderRequestBasePriorityEnum[
                            key as keyof typeof OrderRequestBasePriorityEnum
                          ]
                        }
                      >
                        {t(
                          `order_request.form.value.priority.${key.toLowerCase()}`
                        )}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup as={Col} lg="4" controlId="fao">
              <Form.Label>{t('order_request.form.label.fao')}</Form.Label>
              <Form.Control
                {...register('fao', {
                  maxLength: {
                    value: 100,
                    message: t('validation.error.order_request.max_length.fao')
                  }
                })}
                maxLength={100}
                isInvalid={!!errors.fao}
                title={errors.fao?.message}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>

            <FormGroup as={Col} lg="8" controlId="address">
              <Form.Label className={classNames({ required: !isValid })}>
                {t('order_request.form.label.address')}
                {defaultAddressChanged && (
                  <span>
                    {' '}
                    <BsInfoCircle
                      size={16}
                      title={t('order_request.form.label.address.warning')}
                      className="text-info"
                    />
                  </span>
                )}
              </Form.Label>
              <Controller
                name="addressId"
                control={control}
                rules={{
                  validate: v => !!(v && parseInt(v, 10) > 0)
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid }
                }) => (
                  <MaSelect
                    aria-label={t('order_request.form.label.address')}
                    title={
                      formatAddress(
                        accountProfile?.client?.addresses?.find(
                          address => address.id === value
                        )
                      ) ?? ''
                    }
                    placeholder={t('order_request.form.placeholder.address')}
                    value={value}
                    isInvalid={invalid}
                    onValueChange={value => {
                      handleAddressChange(onChange, value)
                    }}
                  >
                    {accountProfile?.client?.addresses?.map(address => (
                      <MaSelectItem
                        key={address.id}
                        value={address.id}
                        title={formatAddress(address)}
                      >
                        {formatAddress(address)}
                      </MaSelectItem>
                    ))}
                  </MaSelect>
                )}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>

          <Row>
            <Controller
              name="confidential"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Form.Label className={styles.confidential}>
                  <MaCheckbox onCheckedChange={onChange} checked={value} />
                  {t('order_request.form.label.confidential')}
                </Form.Label>
              )}
            />
          </Row>

          <Row>
            <FormGroup as={Col} lg="12" controlId="reason">
              <Form.Label>{t('order_request.form.label.reason')}</Form.Label>
              <Form.Control
                as="textarea"
                className={styles.reason}
                {...register('reason', { maxLength: 500 })}
                maxLength={500}
              />
              <Form.Control.Feedback type="invalid" />
            </FormGroup>
          </Row>
        </fieldset>
      </Form>

      <OrderRequestSummaryBreakdown lineItems={lineItems} />
    </MaConfirm>
  )
}

export default OrderRequestFormModal
