import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type OrderRequest } from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import MaDetailsTabs from '../../common/components/MaDetailsTabs'
import MaTab from '../../common/models/MaTab'
import { OrderRequestDetailsTab } from '../types/order-request-details-tab'
import OrderRequestHistory from './OrderRequestHistory'
import OrderRequestDeliveryInfo from './OrderRequestDeliveryInfo'
import styles from '../assets/scss/OrderRequest.module.scss'

function OrderRequestDetailsPanel ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  const { t } = useTranslation()

  const tabs = [
    new MaTab(
      OrderRequestDetailsTab.DELIVERY_INFO,
      <OrderRequestDeliveryInfo orderRequest={orderRequest} />,
      true
    ),
    new MaTab(
      OrderRequestDetailsTab.HISTORY,
      <OrderRequestHistory orderRequest={orderRequest} />,
      true
    )
  ]

  const activeTab = location.hash.substring(1) || tabs[0].key

  const setActiveTab = (tab: string): void => {
    location.hash = tab ?? ''
  }

  return (
    <MaPanel>
      <MaPanel.Header className={styles['details-panel-header']}>
        <h4>{t('order_request.details.title')}</h4>
      </MaPanel.Header>

      <MaPanel.Body className={styles['details-panel-body']}>
        <MaDetailsTabs
          tabs={tabs}
          activeTab={activeTab}
          onSelectTab={setActiveTab}
        />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default OrderRequestDetailsPanel
