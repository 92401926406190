export enum FinanceSystemPermission {
  IsSageFinanceUser = 'IsSageFinanceUser',
  IsXeroFinanceUser = 'IsXeroFinanceUser',
  IsDimensionsFinanceUser = 'IsDimensionsFinanceUser',
  IsExchequerFinanceUser = 'IsExchequerFinanceUser',
  IsPegasusFinanceUser = 'IsPegasusFinanceUser',
  IsSage200FinanceUser = 'IsSage200FinanceUser',
  IsSageRedXFinanceUser = 'IsSageRedXFinanceUser',
  IsSageSygnatureFinanceUser = 'IsSageSygnatureFinanceUser',
  IsSageProportionalbyInvoiceLineFinanceUser = 'IsSageProportionalbyInvoiceLineFinanceUser',
  IsSageProportionalbyInvoiceFinanceUser = 'IsSageProportionalbyInvoiceFinanceUser',
  IsAccountIQFinanceUser = 'IsAccountIQFinanceUser',
  IsSage200ItaconixFinanceUser = 'IsSage200ItaconixFinanceUser',
  IsSageADCBiotechFinanceUser = 'IsSageADCBiotechFinanceUser',
  IsXeroProprtionalbyInvoiceFinanceUser = 'IsXeroProprtionalbyInvoiceFinanceUser',
  IsXeroUSCustomFinanceUser = 'IsXeroUSCustomFinanceUser',
  IsNetSuiteUSCustomFinanceUser = 'IsNetSuiteUSCustomFinanceUser',
  IsSageProportionalVATShippingbyInvoiceFinanceUser = 'IsSageProportionalVATShippingbyInvoiceFinanceUser',
  IsSageAMRFinanceUser = 'IsSageAMRFinanceUser',
  IsSage200BicycleFinanceUser = 'IsSage200BicycleFinanceUser',
  IsQuickBooksFinanceUser = 'IsQuickBooksFinanceUser',
  IsSage50USFinanceUser = 'IsSage50USFinanceUser',
  IsSage200MDCFinanceUser = 'IsSage200MDCFinanceUser',
  IsSage50PsiOxusFinanceUser = 'IsSage50PsiOxusFinanceUser',
  IsXeroByLineFinanceUser = 'IsXeroByLineFinanceUser',
  IsNetSuiteFinanceUser = 'IsNetSuiteFinanceUser',
  IsXeroInvoiceLinesV2FinanceUser = 'IsXeroInvoiceLinesV2FinanceUser',
}

export const FinanceSystemExports: Record<
string,
{ title: string, url: string }
> = {
  [FinanceSystemPermission.IsSageFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_50',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=1'
  },
  [FinanceSystemPermission.IsXeroFinanceUser]: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=2'
  },
  [FinanceSystemPermission.IsDimensionsFinanceUser]: {
    title: 'main_nav.purchasing.export_to_dimensions',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=3'
  },
  [FinanceSystemPermission.IsExchequerFinanceUser]: {
    title: 'main_nav.purchasing.export_to_exchequer',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=4'
  },
  [FinanceSystemPermission.IsPegasusFinanceUser]: {
    title: 'main_nav.purchasing.export_to_pegasus',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=5'
  },
  [FinanceSystemPermission.IsSage200FinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=6'
  },
  [FinanceSystemPermission.IsSageRedXFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=7'
  },
  [FinanceSystemPermission.IsSageSygnatureFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=8'
  },
  [FinanceSystemPermission.IsSageProportionalbyInvoiceLineFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=9'
  },
  [FinanceSystemPermission.IsSageProportionalbyInvoiceFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=10'
  },
  [FinanceSystemPermission.IsAccountIQFinanceUser]: {
    title: 'main_nav.purchasing.export_to_account_iq',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=11'
  },
  [FinanceSystemPermission.IsSage200ItaconixFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=13'
  },
  [FinanceSystemPermission.IsSageADCBiotechFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=14'
  },
  [FinanceSystemPermission.IsXeroProprtionalbyInvoiceFinanceUser]: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=15'
  },
  [FinanceSystemPermission.IsXeroUSCustomFinanceUser]: {
    title: 'main_nav.purchasing.export_to_xero_us',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=16'
  },
  [FinanceSystemPermission.IsNetSuiteUSCustomFinanceUser]: {
    title: 'main_nav.purchasing.export_to_netsuite',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=17'
  },
  [FinanceSystemPermission.IsSageProportionalVATShippingbyInvoiceFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=18'
  },
  [FinanceSystemPermission.IsSageAMRFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=19'
  },
  [FinanceSystemPermission.IsSage200BicycleFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=20'
  },
  [FinanceSystemPermission.IsQuickBooksFinanceUser]: {
    title: 'main_nav.purchasing.export_to_quickbooks',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=21'
  },
  [FinanceSystemPermission.IsSage50USFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_50_us',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=22'
  },
  [FinanceSystemPermission.IsSage200MDCFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_50_mdc',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=23'
  },
  [FinanceSystemPermission.IsSage50PsiOxusFinanceUser]: {
    title: 'main_nav.purchasing.export_to_sage_50_psioxus',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=24'
  },
  [FinanceSystemPermission.IsXeroByLineFinanceUser]: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=25'
  },
  [FinanceSystemPermission.IsNetSuiteFinanceUser]: {
    title: 'main_nav.purchasing.export_to_netsuite',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=26'
  },
  [FinanceSystemPermission.IsXeroInvoiceLinesV2FinanceUser]: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=27'
  }
}
