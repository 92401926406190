import {
  type OrderRequestLineItem,
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum
} from '@amici/myamici-api-client'
import { useEffect, useRef, useState, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { type ViewType } from '../types/order-request-details-summary-view-type'
import { UserPermission } from '../../common/types/user-permission'
import useAccounts from '../../common/hooks/useAccounts'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import MaPanel from '../../common/components/MaPanel'
import OrderRequestSpendPanelHeaderControls from './OrderRequestSpendPanelHeaderControls'
import OrderRequestSpendGroupedView from './OrderRequestSpendGroupedView'
import OrderRequestEditLineItemModal from './OrderRequestEditLineItemModal'
import OrderRequestRemoveLineItemModal from './OrderRequestRemoveLineItemModal'
import styles from '../assets/scss/OrderRequest.module.scss'

const FULL_VIEW_MIN_WIDTH_PX = 705

function OrderRequestSpendPanel ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactNode {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { lineItems, isLoading } = useOrderRequestLineItems(orderRequest.id)
  const [activeView, setActiveView] = useState<ViewType>('grouped')
  const { supplierIds } = useOrderRequestSummary(lineItems)
  const [expandedSupplierIds, setExpandedSupplierIds] = useState<string[]>([])
  const [lineItemToEdit, setLineItemToEdit] = useState<
  OrderRequestLineItem | undefined
  >()
  const [lineItemToRemove, setLineItemToRemove] = useState<
  OrderRequestLineItem | undefined
  >()

  const ref = useRef(null)
  const compactView = !useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  // Expanded sections state cleanup
  useEffect(() => {
    expandedSupplierIds.forEach(id => {
      if (!supplierIds.includes(id)) {
        setExpandedSupplierIds(expandedIds =>
          expandedIds.filter(expandedId => expandedId !== id)
        )
      }
    })
  }, [expandedSupplierIds, supplierIds])

  const isFinanceUser = activeAccount?.permissions.some(permission =>
    [UserPermission.IsFinance, UserPermission.IsFinanceUser].includes(
      permission as UserPermission
    )
  )
  const canChange = !!(
    orderRequest.order_status === OrderRequestAllOfOrderStatusEnum.NEW &&
    (activeAccount?.accountId === orderRequest.requested_by?.id ||
      isFinanceUser)
  )

  const handleToggleExpandedSupplier = (supplierId: string): void => {
    if (expandedSupplierIds.includes(supplierId)) {
      setExpandedSupplierIds(
        expandedSupplierIds.filter(id => id !== supplierId)
      )
    } else {
      setExpandedSupplierIds([...expandedSupplierIds, supplierId])
    }
  }

  const handleEditFormOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToEdit(lineItem)
  }

  const handleEditFormClose = (): void => {
    setLineItemToEdit(undefined)
  }

  const handleRemoveModalOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToRemove(lineItem)
  }

  const handleRemoveModalClose = (): void => {
    setLineItemToRemove(undefined)
  }

  if (!orderRequest || isLoading) {
    return null
  }

  return (
    <>
      <MaPanel className={styles['spend-panel']} ref={ref}>
        <MaPanel.Header className={styles['spend-panel-header']}>
          <h4>{t('order_request.spend.title')}</h4>

          <OrderRequestSpendPanelHeaderControls
            compactView={compactView}
            activeView={activeView}
            allCollapsed={expandedSupplierIds.length === 0}
            onCollapseAll={() => {
              setExpandedSupplierIds([])
            }}
            onExpandAll={() => {
              setExpandedSupplierIds(supplierIds)
            }}
            onViewChange={view => {
              setActiveView(view)
            }}
          />
        </MaPanel.Header>

        <MaPanel.Body className={styles['spend-panel-body']}>
          {(compactView || activeView === 'grouped') && (
            <OrderRequestSpendGroupedView
              lineItems={lineItems}
              expandedSupplierIds={expandedSupplierIds}
              compactView={compactView}
              canChange={canChange}
              onToggleExpanded={handleToggleExpandedSupplier}
              onEdit={handleEditFormOpen}
              onRemove={handleRemoveModalOpen}
            />
          )}
        </MaPanel.Body>
      </MaPanel>

      <OrderRequestEditLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToEdit}
        onClose={handleEditFormClose}
      />

      <OrderRequestRemoveLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToRemove}
        onClose={handleRemoveModalClose}
      />
    </>
  )
}

export default OrderRequestSpendPanel
