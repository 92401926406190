import { useState, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Badge, Container } from 'react-bootstrap'
import { UserPermission } from '../../common/types/user-permission'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import useAccounts from '../../common/hooks/useAccounts'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import OrderRequestSummaryPanel from '../components/OrderRequestSummaryPanel'
import OrderRequestDetailsPanel from '../components/OrderRequestDetailsPanel'
import OrderRequestSpendPanel from '../components/OrderRequestSpendPanel'
import OrderRequestEditSummaryModal from '../components/OrderRequestEditSummaryModal'
import OrderRequestHeader from '../components/OrderRequestHeader'
import styles from '../assets/scss/OrderRequest.module.scss'
import OrderRequestCancelSummaryModal from '../components/OrderRequestCancelSummaryModal'
import { OrderRequestAllOfOrderStatusEnum } from '@amici/myamici-api-client'

function OrderRequest (): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { orderRequestId } = useParams()
  const { data: orderRequest, isLoading: isLoadingOrderRequest } =
    useOrderRequest(orderRequestId)
  const { isLoading: isLoadingLineItems } =
    useOrderRequestLineItems(orderRequestId)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const isLoading = isLoadingOrderRequest || isLoadingLineItems

  const isCancelled =
    orderRequest?.order_status === OrderRequestAllOfOrderStatusEnum.CANCELLED

  const canEdit =
    [UserPermission.IsFinance, UserPermission.IsFinanceUser].some(permission =>
      activeAccount?.permissions.includes(permission)
    ) || orderRequest?.requested_by?.id === activeAccount?.accountId

  return (
    <Container fluid="auto" className="ma-page">
      <OrderRequestHeader />

      <div className={styles.content}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && orderRequest && (
          <>
            <div className={styles['summary-header']}>
              <Badge pill={true} bg="info" text="dark">
                {t(
                  `order_request.status.${orderRequest?.order_status?.toLowerCase()}`
                )}
              </Badge>

              <h2>{orderRequest?.order_ref}</h2>

              {!isCancelled && canEdit && (
                <MaActionMenu>
                  <MaActionMenuItem
                    onClick={() => {
                      setShowEditModal(true)
                    }}
                  >
                    {t('order_request.action.edit')}
                  </MaActionMenuItem>

                  <MaActionMenuItem
                    onClick={() => {
                      setShowCancelModal(true)
                    }}
                  >
                    {t('order_request.action.cancel')}
                  </MaActionMenuItem>
                </MaActionMenu>
              )}
            </div>

            <OrderRequestSummaryPanel orderRequest={orderRequest} />

            <OrderRequestSpendPanel orderRequest={orderRequest} />

            <OrderRequestDetailsPanel orderRequest={orderRequest} />

            {canEdit && orderRequest && (
              <OrderRequestEditSummaryModal
                orderRequestId={orderRequestId}
                show={showEditModal}
                onClose={() => {
                  setShowEditModal(false)
                }}
              />
            )}

            {canEdit && orderRequest && (
              <OrderRequestCancelSummaryModal
                orderRequest={orderRequest}
                show={showCancelModal}
                onClose={() => {
                  setShowCancelModal(false)
                }}
              />
            )}
          </>
        )}
      </div>
    </Container>
  )
}

export default OrderRequest
