import { type HTMLAttributes, type ReactElement } from 'react'
import {
  type SpendCategory,
  type SpendCategoryField,
  SpendCategoryFieldStatusEnum
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useSpendCategories from '../hooks/useSpendCategories'
import SpendCategorySelect from './SpendCategorySelect'
import useSpendCategoriesTree from '../hooks/useSpendCategoriesTree'
import styles from '../assets/scss/SpendCategoryField.module.scss'

interface SpendCategoryFieldSelectProps extends HTMLAttributes<HTMLDivElement> {
  selectedSpendCategories: SpendCategory[]
  excludeInactive?: boolean
  isBusy?: boolean
  compactView?: boolean
  allowEmpty: boolean
  onValueChange: (newSpendCategory: SpendCategory) => void
}

function SpendCategoryFieldSelect ({
  selectedSpendCategories,
  excludeInactive,
  isBusy,
  compactView,
  allowEmpty,
  onValueChange
}: SpendCategoryFieldSelectProps): ReactElement {
  const { t } = useTranslation()
  const { data, isLoading } = useSpendCategories()
  const { getEmptySpendCategory } = useSpendCategoriesTree([])

  const spendCategoryFields = (data ?? []).filter(spendCategoryField =>
    excludeInactive
      ? spendCategoryField.status === SpendCategoryFieldStatusEnum.ACTIVE
      : true
  )

  const getSpendCategoryValue = (
    spendCategoryField: SpendCategoryField
  ): string => {
    return (
      selectedSpendCategories
        .find(
          selectedSpendCategory =>
            selectedSpendCategory.field_id ===
            spendCategoryField.spend_categories[0].field_id
        )
        ?.id.toString() ?? ''
    )
  }

  const onUpdate = (spendCategoryId: number, index: number): void => {
    const spendCategoryField = spendCategoryFields[index]

    const newSpendCategory =
      spendCategoryId === -1
        ? getEmptySpendCategory(spendCategoryField.id ?? '0')
        : spendCategoryField.spend_categories.find(
          spendCategory => spendCategory.id === spendCategoryId
        )

    if (!newSpendCategory) {
      return
    }
    onValueChange(newSpendCategory)
  }

  return (
    <div
      className={classNames(styles['spend-category-fields'], {
        [styles.compact]: compactView
      })}
      data-testid="spend-category-fields"
    >
      {spendCategoryFields.map(
        (spendCategoryField, index) =>
          spendCategoryField.spend_categories.length > 0 && (
            <div
              className={classNames(styles['spend-category'], {
                [styles.compact]: compactView
              })}
              key={`${spendCategoryField.name}-${index}`}
            >
              <span className={styles.name}>{spendCategoryField.name}</span>
              <SpendCategorySelect
                data-testid={`spend-category-dropdown-${spendCategoryField.name}`}
                disabled={isBusy || isLoading}
                value={getSpendCategoryValue(spendCategoryField)}
                placeholder={t('spend_categories.label.none')}
                spendCategories={spendCategoryField.spend_categories}
                allowEmpty={allowEmpty}
                onValueChange={value => {
                  onUpdate(parseInt(value), index)
                }}
              />
            </div>
          )
      )}
    </div>
  )
}

export default SpendCategoryFieldSelect
