import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import {
  type OrderRequestsApiUpdateOrderRequestLineItemRequest,
  type OrderRequestLineItem,
  type OrderRequestLineItemsResource,
  type OrderRequestsApiDeleteOrderRequestLineItemRequest
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useOrderRequestSummary from './useOrderRequestSummary'

interface UseOrderRequestLineItemsHook {
  count: number
  currency: string
  total: number
  isLoading: boolean
  isUpdating: boolean
  isRemoving: boolean
  lineItems: OrderRequestLineItem[]
  updateLineItem: (
    lineItem: OrderRequestLineItem,
  ) => Promise<OrderRequestLineItem>
  removeLineItem: (lineItem: OrderRequestLineItem) => Promise<void>
}

function useOrderRequestLineItems (
  orderRequestId: string = ''
): UseOrderRequestLineItemsHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequestLineItems,
      updateOrderRequestLineItem,
      deleteOrderRequestLineItem
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR<OrderRequestLineItemsResource, Error>(
    accountId && orderRequestId
      ? ['order-request-line-items', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getOrderRequestLineItems, { orderRequestId, accountId })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiUpdateOrderRequestLineItemRequest }
    ) => await fetcher(updateOrderRequestLineItem, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerRemove, isMutating: isRemoving } = useSWRMutation(
    accountId ? ['order-request-line-items', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiDeleteOrderRequestLineItemRequest }
    ) => {
      await fetcher(deleteOrderRequestLineItem, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const updateLineItem = async (
    lineItem: OrderRequestLineItem
  ): Promise<OrderRequestLineItem> =>
    await triggerUpdate({
      orderRequestLineItem: lineItem,
      orderRequestId,
      lineItemId: lineItem.line_item.id,
      accountId
    })

  const removeLineItem = async (
    lineItem: OrderRequestLineItem
  ): Promise<void> => {
    await triggerRemove({
      lineItemId: lineItem.line_item.id,
      orderRequestId,
      accountId
    })
  }

  const lineItems = data?.content ?? []

  const { getTotal } = useOrderRequestSummary(lineItems)

  const count = lineItems.length

  const currency = lineItems[0]?.line_item.currency ?? 'GBP'

  const total = getTotal(currency)

  return {
    lineItems,
    count,
    currency,
    total,
    isLoading,
    isUpdating,
    isRemoving,
    updateLineItem,
    removeLineItem
  }
}

export default useOrderRequestLineItems
