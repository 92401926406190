import useSWR from 'swr'
import { type AccountResources } from '@amici/myamici-api-client'
import useApi from './useApi'
import useAccounts from './useAccounts'

interface UseAllAccountsHook {
  allAccounts?: AccountResources
  isLoading: boolean
}

function useAllAccounts (): UseAllAccountsHook {
  const { activeAccount } = useAccounts()

  const { accountsApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data: allAccounts, isLoading } = useSWR<AccountResources, Error>(
    accountId ? ['all-accounts', accountId] : null,
    async () => await fetcher(accountsApi.getAll, { accountId })
  )

  return {
    allAccounts,
    isLoading
  }
}

export default useAllAccounts
