import { type ReactElement, useContext } from 'react'
import { BsBell, BsListCheck, BsStar, BsXLg } from 'react-icons/bs'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useIsMobile from '../../common/hooks/useIsMobile'
import useTasks from '../../tasks/hooks/useTasks'
import useFavourites from '../../favourites/hooks/useFavourites'
import {
  QuickAccessAction,
  type QuickAccessTabs
} from '../hooks/useQuickAccessState'
import {
  QuickAccessContext,
  QuickAccessDispatchContext
} from './QuickAccessContextProvider'
import useUnreadNotifications from '../../notifications/hooks/useUnreadNotifications'
import MaIconButton from '../../common/components/MaIconButton'
import Tasks from '../../tasks/components/Tasks'
import Favourites from '../../favourites/components/Favourites'
import Notifications from '../../notifications/components/Notifications'
import styles from '../assets/scss/QuickAccess.module.scss'

interface QuickAccessProps {
  expanded: boolean
  onExpand: () => void
  onCollapse: () => void
}

function QuickAccess ({
  expanded,
  onExpand,
  onCollapse
}: QuickAccessProps): ReactElement | null {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const quickAccessState = useContext(QuickAccessContext)
  const quickAccessDispatch = useContext(QuickAccessDispatchContext)
  const { totalTasks } = useTasks()
  const { totalFavourites } = useFavourites()
  const notificationsCount = useUnreadNotifications()

  const handleTabSelect = (tab: QuickAccessTabs): void => {
    if (quickAccessState.activeTab === tab && expanded) {
      onCollapse()
      return
    }

    quickAccessDispatch({
      type: QuickAccessAction.SET_ACTIVE_TAB,
      value: tab
    })
    onExpand()
  }

  if (isMobile) {
    return null
  }

  // TODO: Consider using Draggable https://www.npmjs.com/package/react-draggable for the column resizing
  return (
    <div className={classNames(styles['quick-access'], { expanded })}>
      <div className={styles.tabs}>
        <MaIconButton
          variant="secondary"
          activeBarPosition="left"
          active={quickAccessState.activeTab === 'tasks' && expanded}
          count={totalTasks}
          onClick={() => {
            handleTabSelect('tasks')
          }}
          data-testid="quick-access-tasks-btn"
        >
          <BsListCheck size={24} />
        </MaIconButton>

        <MaIconButton
          variant="secondary"
          activeBarPosition="left"
          active={quickAccessState.activeTab === 'notifications' && expanded}
          count={notificationsCount}
          onClick={() => {
            handleTabSelect('notifications')
          }}
          data-testid="quick-access-notifications-btn"
        >
          <BsBell size={24} />
        </MaIconButton>

        <MaIconButton
          variant="secondary"
          activeBarPosition="left"
          active={quickAccessState.activeTab === 'favourites' && expanded}
          onClick={() => {
            handleTabSelect('favourites')
          }}
          data-testid="quick-access-favourites-btn"
        >
          <BsStar size={24} />
        </MaIconButton>
      </div>

      {expanded && quickAccessState.activeTab && (
        <div className={styles.content}>
          <div className={styles.header}>
            <h3 className={styles.title}>
              {t(`quick_access.tab.title.${quickAccessState.activeTab ?? ''}`)}
              {quickAccessState.activeTab === 'favourites' &&
                totalFavourites > 0 &&
                ` (${totalFavourites})`}
            </h3>
            <MaIconButton
              className={styles['close-btn']}
              variant="secondary"
              onClick={onCollapse}
              data-testid="quick-access-close-btn"
            >
              <BsXLg size={24} />
            </MaIconButton>
          </div>
          <div className={styles.body}>
            {quickAccessState.activeTab === 'tasks' && <Tasks />}
            {quickAccessState.activeTab === 'notifications' && (
              <Notifications />
            )}
            {quickAccessState.activeTab === 'favourites' && <Favourites />}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuickAccess
