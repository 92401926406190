import {
  type FormEvent,
  Fragment,
  type ReactElement,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  type DeliveryReceipt,
  type OrderLineItemResource,
  type OrderResource
} from '@amici/myamici-api-client'
import { mutate } from 'swr'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Offcanvas, Row } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { addMonths, format } from 'date-fns'
import { type DateRange } from 'react-day-picker'
import { BsXLg } from 'react-icons/bs'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import OrderSearchForm from '../components/OrderSearchForm'
import useOrdersPageState, {
  OrdersPageAction
} from '../hooks/useOrdersPageState'
import useAccounts from '../../common/hooks/useAccounts'
import MaDateRangePicker from '../../common/components/MaDateRangePicker'
import useOrders from '../hooks/useOrders'
import filtersMapToParam from '../utils/filters-map-to-param'
import { OrderSortType } from '../types/order-sort-type'
import { OrderSearchType } from '../types/order-search-type'
import { type FacetFilters } from '../types/facet-filters'
import filtersQueryStringToMap from '../utils/filters-query-string-to-map'
import MaPageTitle from '../../common/components/MaPageTitle'
import MaToast from '../../common/components/MaToast'
import MaIconButton from '../../common/components/MaIconButton'
import { MaSelectItem } from '../../common/components/MaSelect'
import OrderSortSelect from '../components/OrderSortSelect'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import ReceiveOrderCard from '../components/ReceiveOrderCard'
import DynamicPagination from '../../common/components/DynamicPagination'
import OrderSearchSummary from '../components/OrderSearchSummary'
import ReceiveLineItemsModal from '../components/ReceiveLineItemsModal'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import styles from '../../common/assets/scss/SearchPage.module.scss'

function ReceiveOrders (): ReactElement | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [queryParams] = useSearchParams()
  const { showToast, closeToast } = useToastNotification()
  const { activeAccount } = useAccounts()

  const [lineItemsToReceive, setLineItemsToReceive] = useState<
  OrderLineItemResource[]
  >([])

  const [orderToReceive, setOrderToReceive] = useState<OrderResource | null>(
    null
  )

  const [showSingleLineItemModal, setShowSingleLineItemModal] = useState(false)

  const term = queryParams.get('term') ?? ''
  const orderSortType =
    (queryParams.get('sort') as OrderSortType) ?? OrderSortType.OrderDate
  const orderSearchType =
    (queryParams.get('searchType') as OrderSearchType) ??
    OrderSearchType.OutstandingOrders

  const dateFrom = queryParams.get('dateFrom') ?? undefined
  const dateTo = queryParams.get('dateTo') ?? undefined

  let filters = ''

  if (orderSearchType === OrderSearchType.OutstandingOrders) {
    filters = 'status:open'
  } else if (orderSearchType === OrderSearchType.ReceivedOrders) {
    filters = 'status:complete'
  } else {
    filters = 'status:open;complete'
  }

  const orderDateRange: DateRange | undefined =
    dateFrom && dateTo
      ? {
          from: new Date(dateFrom),
          to: new Date(dateTo)
        }
      : undefined

  const filtersMap: FacetFilters = useMemo(
    () => filtersQueryStringToMap(filters),
    [filters]
  )

  const { ordersPageState, dispatch } = useOrdersPageState({
    term,
    filters: filtersMap,
    filtersUpdated: false,
    showOffcanvasFilters: false
  })

  const {
    data,
    mutate: mutateOrders,
    isLoading
  } = useOrders({
    term,
    page: parseInt(queryParams.get('page')?.toString() ?? '1', 10),
    sort: orderSortType,
    orderSearchType,
    dateFrom,
    dateTo,
    facetFilter: filtersMapToParam(filtersMap),
    returnFacets: false
  })

  useEffect(() => {
    dispatch({
      type: OrdersPageAction.PATCH_STATE,
      value: {
        term,
        filters: filtersMap
      }
    })

    if (dateFrom === undefined && dateTo === undefined) {
      const newQueryParams = new URLSearchParams(queryParams)

      const defaultDateFrom = format(addMonths(new Date(), -3), 'yyyy-MM-dd')
      const defaultDateTo = format(new Date(), 'yyyy-MM-dd')

      newQueryParams.set('dateFrom', defaultDateFrom)
      newQueryParams.set('dateTo', defaultDateTo)

      navigate(`?${newQueryParams.toString()}`, { replace: true })
    }
  }, [dateFrom, dateTo, dispatch, filtersMap, navigate, queryParams, term])

  const handlePageChange = (page: number): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('page', page.toString())
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleDateRangeChange = (range: DateRange | undefined): void => {
    const newQueryParams = new URLSearchParams(queryParams)

    if (range?.from) {
      newQueryParams.set('dateFrom', format(range.from, 'yyyy-MM-dd'))
    } else {
      newQueryParams.set('dateFrom', '')
    }

    if (range?.to) {
      newQueryParams.set('dateTo', format(range.to, 'yyyy-MM-dd'))
    } else if (range?.from) {
      newQueryParams.set('dateTo', format(range.from, 'yyyy-MM-dd'))
    } else {
      newQueryParams.set('dateTo', '')
    }

    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSearchTypeChange = (searchType: OrderSearchType): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('searchType', searchType)
    newQueryParams.set('page', '1')
    newQueryParams.delete('filters')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSortChange = (value: OrderSortType): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('sort', value)
    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    updateSearchParams()
  }

  const updateSearchParams = (): void => {
    const newQueryParams = new URLSearchParams(queryParams)

    newQueryParams.set('sort', orderSortType)
    newQueryParams.set('page', '1')
    newQueryParams.set('term', ordersPageState.term.trim())

    dispatch({
      type: OrdersPageAction.SET_STATE,
      value: {
        ...ordersPageState,
        filtersUpdated: false,
        showOffcanvasFilters: false,
        term: ordersPageState.term.trim()
      }
    })

    navigate(`?${newQueryParams.toString()}`)
  }

  const searchTypeOptions = [
    {
      value: OrderSearchType.OutstandingOrders,
      label: t('order.filters.select.outstanding_orders')
    },
    {
      value: OrderSearchType.ReceivedOrders,
      label: t('order.filters.select.received_orders')
    },
    {
      value: OrderSearchType.AllOrders,
      label: t('order.filters.select.all_orders')
    }
  ]

  const sortOptions = (): ReactElement => {
    return (
      <Fragment>
        <MaSelectItem value={OrderSortType.OrderDate}>
          {t('order.sort.select.reference')}
        </MaSelectItem>
        <MaSelectItem value={OrderSortType.Supplier}>
          {t('order.sort.select.supplier')}
        </MaSelectItem>
      </Fragment>
    )
  }

  const handleReceiveItemsModalOpen = (
    order: OrderResource,
    items: OrderLineItemResource[],
    showLineItemModal: boolean
  ): void => {
    if (showLineItemModal) {
      setLineItemsToReceive(items)
    } else {
      const unreceivedItems = items.filter(
        item => item.quantity !== item.quantity_received
      )
      setLineItemsToReceive(unreceivedItems)
    }
    setOrderToReceive(order)
    setShowSingleLineItemModal(showLineItemModal)
  }

  const handleReceiveItemsModalClose = (): void => {
    setLineItemsToReceive([])
    setOrderToReceive(null)
    setShowSingleLineItemModal(false)
  }

  const handleReceiveSuccess = async (
    items: DeliveryReceipt[]
  ): Promise<void> => {
    const orderId = orderToReceive?.id
    const [item] = items
    const isCommentOnly = !!item.comment && item.quantity === 0

    setOrderToReceive(null)
    setShowSingleLineItemModal(false)

    await mutateOrders()
    await mutate(['order-items', orderId, activeAccount?.accountId])

    showReceivedItemsSuccessNotification(isCommentOnly)
  }

  const handleReceiveError = (): void => {
    setOrderToReceive(null)
    setShowSingleLineItemModal(false)
    showReceivedItemsErrorNotification()
  }

  const getToastNotificationReference = (): string => {
    return lineItemsToReceive.length > 1
      ? orderToReceive?.reference ?? ''
      : lineItemsToReceive[0].product?.description ?? ''
  }

  const showReceivedItemsSuccessNotification = (
    isCommentOnly?: boolean
  ): void => {
    const toastId = Date.now()
    showToast(
      toastId,
      <MaToast
        type="dark"
        onClose={() => {
          closeToast(toastId)
        }}
      >
        <p>
          {isCommentOnly
            ? t('receive_order.item.add_comment_success_message')
            : t('receive_order.item.success_message', {
              reference: getToastNotificationReference()
            })}
        </p>
      </MaToast>
    )
  }

  const showReceivedItemsErrorNotification = (): void => {
    const toastId = Date.now()

    showToast(
      toastId,
      <MaToast
        type="danger"
        onClose={() => {
          closeToast(toastId)
        }}
      >
        <p>
          {t('receive_order.item.error_message', {
            reference: getToastNotificationReference()
          })}
        </p>
      </MaToast>
    )
  }

  return (
    <Container
      fluid="auto"
      className={classNames('ma-page', { [styles.mobile]: isMobile })}
    >
      <MaPageTitle>{t('receive_orders.title')}</MaPageTitle>

      <div className={styles['search-form-row']}>
        <OrderSearchForm
          className={styles['search-input']}
          search={ordersPageState.term}
          type={orderSearchType}
          onTypeChange={handleSearchTypeChange}
          onSubmit={handleSubmit}
          searchTypeOptions={searchTypeOptions}
          onSearchChange={e => {
            dispatch({
              type: OrdersPageAction.UPDATE_SEARCH_TERM,
              value: e.target.value
            })
          }}
        />

        {!isMobile && (
          <div className={styles['search-options']}>
            <MaDateRangePicker
              value={orderDateRange}
              onDateRangeChange={handleDateRangeChange}
              placeholder={t('order.filters.date_range.placeholder')}
            />
          </div>
        )}
      </div>

      <div className={styles['search-results']}>
        {isMobile && (
          <Offcanvas
            className={styles['offcanvas-filters']}
            show={ordersPageState.showOffcanvasFilters}
            placement="start"
            backdrop={false}
            responsive="md"
            onHide={() => {
              dispatch({ type: OrdersPageAction.CLOSE_OFFCANVAS_FILTERS })
            }}
          >
            <Offcanvas.Header className={styles['offcanvas-filters-header']}>
              <h2 className={styles['offcanvas-filters-title']}>
                {t('common.button.labels.sort_filter')}
              </h2>
              <MaIconButton
                onClick={() => {
                  dispatch({
                    type: OrdersPageAction.CLOSE_OFFCANVAS_FILTERS
                  })
                }}
              >
                <BsXLg size={24} />
              </MaIconButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className={classNames(styles.sorting, {
                  [styles.mobile]: isMobile
                })}
              >
                <MaDateRangePicker
                  value={orderDateRange}
                  onDateRangeChange={handleDateRangeChange}
                  placeholder={t('order.filters.date_range.placeholder')}
                />
                <OrderSortSelect
                  value={orderSortType}
                  onChange={handleSortChange}
                  sortOptions={sortOptions()}
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        )}

        <div className={styles['card-grid']}>
          {isMobile && (
            <div className={styles['search-controls']}>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="light"
                    className={styles['offcanvas-filters-toggle-btn']}
                    onClick={() => {
                      dispatch({
                        type: OrdersPageAction.OPEN_OFFCANVAS_FILTERS
                      })
                    }}
                  >
                    {t('common.button.labels.sort_filter')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <Row>
            <Col>
              <OrderSearchSummary
                total={data?.totalElements}
                dateRange={orderDateRange}
                filters={filtersMap ?? undefined}
                term={term}
              />
            </Col>

            {!isMobile && (
              <Col>
                <div className={styles.sorting}>
                  <OrderSortSelect
                    value={orderSortType}
                    onChange={handleSortChange}
                    sortOptions={sortOptions()}
                  />
                </div>
              </Col>
            )}
          </Row>

          <div data-testid="receive-orders">
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}

            <Row>
              {data?.content?.map(order => (
                <Col xs={12} key={order.id}>
                  <ReceiveOrderCard
                    order={order}
                    handleReceiveItemsModalOpen={handleReceiveItemsModalOpen}
                  />
                </Col>
              ))}
            </Row>
          </div>
          {data && (data?.totalElements as number) > 0 && (
            <div className={styles.pagination}>
              <DynamicPagination
                currentPage={data?.page ?? 0}
                totalPages={data?.totalPages ?? 0}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>

        {lineItemsToReceive.length > 0 && (
          <ReceiveLineItemsModal
            variant={showSingleLineItemModal ? 'item' : 'order'}
            order={orderToReceive}
            lineItems={lineItemsToReceive}
            onClose={handleReceiveItemsModalClose}
            onSuccess={(items: DeliveryReceipt[]) => {
              void handleReceiveSuccess(items)
            }}
            onError={handleReceiveError}
          />
        )}
      </div>
    </Container>
  )
}

export default ReceiveOrders
