import { type RefObject, useLayoutEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

function useMinWidthObserver (
  ref: RefObject<Element> | null | undefined,
  minElementWidth: number
): boolean {
  const { width = 0 } = useResizeObserver({ ref, box: 'border-box' })
  const [inRange, setInRange] = useState(true)

  useLayoutEffect(() => {
    setInRange(width >= minElementWidth)
  }, [minElementWidth, width])

  return inRange
}

export default useMinWidthObserver
