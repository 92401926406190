import {
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement,
  type ForwardedRef
} from 'react'
import classNames from 'classnames'
import styles from '../assets/scss/MaPanel.module.scss'

export function MaPanelHeader ({
  text,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> &
PropsWithChildren & { text?: string }): ReactElement {
  return (
    <div
      className={classNames(styles['panel-header'], className)}
      {...props}
      data-testid="panel-header"
    >
      {text && <h4 className={styles.title}>{text}</h4>}

      {!text && children}
    </div>
  )
}

export function MaPanelBody ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> & PropsWithChildren): ReactElement {
  return (
    <div
      className={classNames(styles['panel-body'], className)}
      {...props}
      data-testid="panel-body"
    >
      {children}
    </div>
  )
}

export function MaPanelFooter ({
  text,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> &
PropsWithChildren & { text?: string }): ReactElement {
  return (
    <div
      className={classNames(styles['panel-footer'], className)}
      {...props}
      data-testid="panel-footer"
    >
      {text || children}
    </div>
  )
}

const MaPanel = forwardRef(function MaPanel (
  {
    children,
    className,
    ...props
  }: HTMLAttributes<HTMLElement> & PropsWithChildren,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  return (
    <section
      ref={ref}
      className={classNames(styles.panel, className)}
      {...props}
      data-testid="panel"
    >
      {children}
    </section>
  )
})

export default Object.assign(MaPanel, {
  Header: MaPanelHeader,
  Body: MaPanelBody,
  Footer: MaPanelFooter
})
