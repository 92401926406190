import { type SortDirection } from '../../common/types/sort-direction'

// TODO: update with the spec when ready
export enum OrderRequestSortBy {
  REFERENCE = 'reference',
  CREATED_DATE = 'created_date',
}

export interface OrderRequestSortOption {
  field: OrderRequestSortBy
  direction: SortDirection
}
